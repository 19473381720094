/**
 * This component shows candidate folder informations
 */
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  withRouter,
  RouteComponentProps,
  useHistory,
  useParams
} from 'react-router-dom';
import JuryService, {
  ApplicationsResponse
} from '../../../../services/api/Jury/Jury';
import Spinner from '../../../../utils/Spinner/Spinner';
import Alert from '../../../../utils/Alert/Alert';
import { me } from '../../../../services/api/Contact/interface';
import FileService from '../../../../services/api/Files/FilesService';
import './OpenFolder.scss';
import AuthService from '../../../../services/api/Auth/AuthService';
import { EducationHistory } from '../../../../services/api/EducationHistory/interface';
import moment from 'moment';
import { saveAs } from 'file-saver';
import Return from '../../../../assets/images/junia-icon-fleche-3.png';
import _ from 'lodash';
import applicationPicklist from '../../../../assets/data/hed_application.json';
import { Facilities } from '../../../../services/api/Facilities/interface';
import FacilitiesService from '../../../../services/api/Facilities/FacilitiesService';

interface ContainerProps {}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const OpenFolder: React.FC<IProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const [candidature, setCandidature] = useState<ApplicationsResponse>();
  const [me, setMe] = useState<me>();

  const { t } = props;
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [folderScore, setFolderScore] = useState<number>(0);
  const [interviewScore, setInterviewScore] = useState<number>(0);
  const [bacData, setBacData] = useState<EducationHistory>();
  const [univOrigin, setNomUnivOrigin] = useState<Facilities>();

  //Translate dur_e_de_l_change__c
  let dureeEchangeInFrench;
  switch (candidature?.dur_e_de_l_change__c) {
    case "To fill in":
      dureeEchangeInFrench = "A renseigner";
      break;
    case "One semester":
      dureeEchangeInFrench = "Un semestre";
      break;
    case "One academic year":
      dureeEchangeInFrench = "Une année académique";
      break;
    case "Joint Degree":
      dureeEchangeInFrench = "Un double diplôme	";
  };

  //Translate specialite_principale_junia__c
  let specialiteJuniaFr = "";
  {
    applicationPicklist.picklistFieldValues[
      'Specialite_principale_Junia__c'
    ].values.map((spe) => {
      if (candidature?.specialite_principale_junia__c && spe.value === candidature?.specialite_principale_junia__c) {
        specialiteJuniaFr = spe.label;
      }
      else {
        console.error("No french value for: ", candidature?.specialite_principale_junia__c, "--> OpenFolder.tsx ~Ligne 72 ");
      }
    });
  }

  //concatenation for the Exchange students
  const concatFormationEchange = candidature?.programme__c + '-' + specialiteJuniaFr + '-' + dureeEchangeInFrench?.toString();

  //translate p_riode_de_d_but__c
  let periodeDebutFr;
  {
    applicationPicklist.picklistFieldValues['P_riode_de_d_but__c'].values.map((periode) => {
      if(candidature?.p_riode_de_d_but__c && periode.value === candidature?.p_riode_de_d_but__c){
        periodeDebutFr = periode.label;
      }
      else {
        console.error("No french value for: ", candidature?.p_riode_de_d_but__c, "--> OpenFolder.tsx ~Ligne 88 ");
      }
    })
  }
  
  Spinner.spinner$.subscribe((s) => {
    if (s !== loading) {
      setLoading(s);
    }
  });

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (me?.id_heroku__c) {
      getApplication();
    }
  }, [me]);

  useEffect(() => {
        getFacility();
    }, [candidature])

  //get facility by SFID
    const getFacility = async () => {
        try {
          let facilitiesResponse = await FacilitiesService.getFacilitiesBySFID(candidature?.Contact?.nom_de_l_universit_d_origine_nouveau__c);
          setNomUnivOrigin(facilitiesResponse.data);
        } catch (error) {
          console.log(
            '🚀 ~ file: OpenFolder.tsx ~ getFacility ~ error',
            error
          );
        }
      };

  useEffect(() => {
    calculatingScore(candidature, fodlerScoreFields, setFolderScore);
    calculatingScore(candidature, interviewScoreFields, setInterviewScore);
    if (candidature?.Contact?.EducationHistorys) {
      findBac(candidature?.Contact?.EducationHistorys);
    }
  }, [candidature]);

  const fodlerScoreFields: string[] =
    candidature?.Contact?.type_tech__c === 'International'
      ? [
          'ad_quation_du_parcours_pr_alable_par_rap__c',
          'r_sultats_scientifiques_ou_sp__c',
          'r_sultats_autres_mati_res_n_1__c',
          'r_sultats_scientifiques_ou_sp_cifiques__c',
          'exp_rience_professionnelle_stage_s_j__c',
          'style_orthographe__c',
          'argumentation_sur_le_choix_de_formation__c',
          'argumentation_sur_le_projet_pro_tudes__c',
          'r_sultats_acad_miques_avant_n_1_mati_r__c'
        ]
      : [
          'appr_ciations_ou_recommandations__c',
          'argumentation_du_choix_de_l_e_cole__c',
          'n_1_r_sultat_l_v__c',
          'n_1_r_sultat_soft_skills__c',
          'n_1_r_sultat_tech_maths__c',
          'n_11_r_sultat_l_v__c',
          'n_11_r_sultat_soft_skills__c',
          'n_r_sultats_soft_skills_app__c',
          'potentiel_et_motivation__c',
          'argumentation_du_projet_professionnel__c',
          'questionnaire_cv_et_lettre_de_motivati__c',
          'r_sultat_tech_maths__c',
          'bonus__c',
          'malus__c',
          'experiences__c',
          'moyenne_au_bac__c'
        ];

  const interviewScoreFields: string[] =
    candidature?.Contact?.type_tech__c === 'International'
      ? [
          'communication__c',
          'connaissance_de_la_formation_de_junia__c',
          'connaissance_de_la_france__c',
          'connaissance_du_domaine_secteur_d_activi__c',
          'efficacit__c',
          'intellect__c',
          'ouverture_d_esprit__c',
          'projet_professionnel_clair_en_ad_quati__c',
          'relationnel__c'
        ]
      : candidature?.Contact?.type_tech__c === 'Francais' &&
        (candidature?.Formation?.type_de_formation__c === 'Apprenti' ||
          candidature?.Formation?.type_de_formation__c ===
            'Formation France apprenti')
      ? [
          'presentation__c',
          'motivation__c',
          'personnalite__c',
          'recul__c',
          'ouverture_d_esprit__c',
          'international__c',
          'comprehension_du_metier_d_ingenieur__c',
          'argumentation_du_projet_professionnel__c',
          'entreprise__c',
          'insertion__c',
          'adequation_junia__c',
          'interet_pour_l_ecole__c'
        ]
      : [
          'connaissance_de_la_formation_de_junia__c',
          'argumentation_sur_le_projet_pro_tudes__c',
          'ad_quation_du_parcours_pr_alable_par_rap__c',
          'comprehension_du_metier_d_ingenieur__c',
          'projection_etudes_formation__c',
          'communication__c_fr',
          'ouverture_d_esprit__c_fr',
          'personnalite__c',
          'international__c'
        ];

  const calculatingScore = (
    form: any,
    fieldRequired: string[],
    setScoreState: (value: React.SetStateAction<number>) => void
  ) => {
    let formFiltered = _.pick(form, fieldRequired);
    if (Object.keys(formFiltered).length > 0) {
      let scoreFromFiltered: any = Object.values(formFiltered).reduce(
        (a: any, b: any) => (!a ? 0 : parseInt(a)) + (!b ? 0 : parseInt(b))
      );
      setScoreState(scoreFromFiltered);
    }
  };

  const findBac = (educationHistory: EducationHistory[]) => {
    const bac = educationHistory.find(
      (education: EducationHistory) =>
        education.type_de_diplome__c === 'Baccalauréat'
    );
    if (bac) {
      setBacData(bac);
    }
  };

  const getMe = async (): Promise<void> => {
    try {
      Spinner.setSpinner(true);
      setMe((await AuthService.me())?.data);
    } catch (err) {
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
        history.goBack();
      }
    }
  };

  const goBack = () => {
    return history.goBack();
  };

  const getApplication = async (): Promise<void> => {
    try {
      setCandidature(
        (await JuryService.getApplication(me?.id_heroku__c, id))?.data
      );
      Spinner.setSpinner(false);
    } catch (err) {
      console.error(err);
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
      }
      history.goBack();
    }
  };

  const str2bytes = (str: any) => {
    let bytes = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  };

  const DownloadZIP = async (): Promise<void> => {
    try {
      Spinner.setSpinner(true);
      const zip = await FileService.downloadZip(candidature?.id_heroku__c);
      let blob = new Blob([str2bytes(zip.data)], { type: 'application/zip' });
      const fileName = candidature?.Contact?.firstname + '-' + candidature?.Contact?.lastname + '_' + candidature?.name + '.zip';
      saveAs(blob, fileName);
      Spinner.setSpinner(false);
    } catch (err) {
      console.log(
        '🚀 ~ file: OpenFolder.tsx ~ line 108 ~ DownloadZIP ~ err',
        err
      );
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
      }
    }
  };

  const openFolder = () => {
    return history.push(`/jury/grade/${candidature?.id_heroku__c}`);
  };

  const getFiles = () => {
    const files = [...(candidature?.Contact?.Files || [])];
    candidature?.Files?.forEach((f) => {
      if (files.some((fi) => fi.name === f)) files.push(f);
    });
    return files;
  };

  return (
    <div>
      {!loading && (
        <div className="open-folder general-container">
          <button className="grade-return" onClick={goBack}>
            <div className="return-icon">
              <img alt="retrun" src={Return} />
            </div>
            <span>{t('backToList')}</span>
          </button>
          <div className="open-folder-container">
            <div className="open-folder-header">
              <div className="folder-name">
                <h5>
                  {t('folder')} {candidature?.name}
                </h5>
                <h5>
                  {
                    candidature?.Contact?.type_tech__c === 'Echange' ? 
                    concatFormationEchange : candidature?.Formation?.name
                  }
                </h5>
                <p className="pending">{candidature?.statut__c}</p>
              </div>
              <div className="dates">
                <div>
                  <h6>{t('requestDate')}</h6>
                  <h6>
                    {moment(candidature?.createddate).format('DD/MM/YYYY')}
                  </h6>
                </div>
                <div>
                  <h6>{t('requestCloseAt')}</h6>
                  <h6>
                    {moment(candidature?.Formation?.date_de_fin__c).format(
                      'DD/MM/YYYY'
                    )}
                  </h6>
                </div>
              </div>
            </div>
            <div className="jury-inset">
              <div className="inset-title">
                <div>
                  <h6>{t('juryFeedback')}</h6>
                </div>
                <div>
                  {/* {candidature?.statut__c === } */}
                  <button className="btn btn-main" onClick={openFolder}>
                    <span>{t('evaluationGrid')}</span>
                  </button>
                </div>
              </div>
              <div className="inset-content">
                <div className="top">
                  {candidature?.Contact?.type_tech__c === 'International' ||
                  candidature?.Formation?.type_de_formation__c === 'Apprenti' ||
                  candidature?.Formation?.type_de_formation__c ===
                    'Formation France apprenti' ? (
                    <div>
                      <p>{t('folderGrade')}</p>
                      <h6>{`${folderScore} / 50 points`}</h6>
                    </div>
                  ) : (
                    <div>
                      <p>{t('folderGrade')}</p>
                      <h6>{`${folderScore} / 60 points`}</h6>
                    </div>
                  )}
                  <div>
                    <p>{t('summonedCandidate')}</p>
                    <h6>
                      {candidature?.convocation_entretien__c
                        ? candidature.convocation_entretien__c
                        : candidature?.convoqu_entretien_fr__c
                        ? candidature.convoqu_entretien_fr__c
                        : null}
                    </h6>
                  </div>
                </div>
                <div className="bottom">
                  <div>
                    <p>{t('finalGrade')}</p>
                    {candidature?.Contact?.type_tech__c === 'International' && (
                      <h6>{`${((interviewScore * 20) / 90).toFixed(
                        1
                      )} / 20 points`}</h6>
                    )}
                    {(candidature?.Contact?.type_tech__c === 'Francais' &&
                      candidature?.Formation?.type_de_formation__c ===
                        'Apprenti') ||
                      (candidature?.Formation?.type_de_formation__c ===
                        'Formation France apprenti' && (
                        <h6>{`${folderScore / 2.5} / 20 points`}</h6>
                      ))}
                    {candidature?.Contact?.type_tech__c === 'Francais' &&
                      candidature?.Formation?.type_de_formation__c !==
                        'Apprenti' &&
                      candidature?.Formation?.type_de_formation__c !==
                        'Formation France apprenti' && (
                        <h6>{`${(folderScore / 3).toFixed(2)} / 20 points`}</h6>
                      )}
                  </div>
                  <div>
                    <p>{t('assignedJury')}</p>
                    <h6>
                      {me?.firstname} {me?.lastname}
                    </h6>
                  </div>
                  {/* <div>
                    <p>{t('signature')}</p>
                    <h6>{candidature?.statut__c}</h6>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="recap">
              <React.Fragment>
                <div className="parcours student">
                  <h5> {t('student')} </h5>
                  <div className="inline-field">
                    <h6> {t('gender')} </h6>
                    <p>
                      {candidature?.Contact?.hed__gender__c === 'Male'
                        ? t('male')
                        : candidature?.Contact?.hed__gender__c === 'Female'
                        ? t('female')
                        : t('nonBinary')}
                    </p>
                  </div>
                  <div className="inline-field">
                    <h6> {t('firstname')} </h6>
                    <p>{candidature?.Contact?.firstname}</p>
                  </div>
                  <div className="inline-field">
                    <h6> {t('lastname')} </h6>
                    <p>{candidature?.Contact?.lastname}</p>
                  </div>
                  <div className="inline-field">
                    <h6> {t('birthdate')} </h6>
                    <p>
                      {moment(candidature?.Contact?.birthdate).format(
                        'DD/MM/YYYY'
                      )}
                    </p>
                  </div>
                  <div className="inline-field">
                    <h6>{t('citizenship')}</h6>
                    <p>{candidature?.Contact?.hed__citizenship__c}</p>
                  </div>
                  {candidature?.Contact?.hed__dual_citizenship__c && (
                    <div className="inline-field">
                      <h6>{t('doubleCitizenship')}</h6>
                      <p>{candidature?.Contact?.hed__dual_citizenship__c}</p>
                    </div>
                  )}
                  <div className="inline-field">
                    <h6>{t('indicative')}</h6>
                    <p>
                      {candidature?.Contact?.indicatif_pays_t_l_phone_mobile__c}
                    </p>
                  </div>
                  <div className="inline-field">
                    <h6>{t('phone')}</h6>
                    <p>{candidature?.Contact?.mobilephone}</p>
                  </div>
                  <div className="inline-field">
                    <h6>{t('email')}</h6>
                    <p>{candidature?.Contact?.email}</p>
                  </div>
                  <div className="inline-field">
                    <h6>{t('programChoice')}</h6>
                    <p>{candidature?.Formation?.nom_formation_tech__c}</p>
                  </div>
                </div>
                <hr></hr>
                {
                  // --- ECHANGE --- //
                candidature?.Contact?.type_tech__c === 'Echange' && (
                  <>
                    <div className="parcours echange">
                      <h5>{t('programChoice')}</h5>
                      <div className="inline-field">
                        <h6>{t('program')}</h6>
                        <p>{candidature?.programme__c}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('juniaSpecialty')}</h6>
                        <p>{specialiteJuniaFr}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('desiredDuration')}</h6>
                        <p>{dureeEchangeInFrench}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('startPeriod')}</h6>
                        <p>{periodeDebutFr}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('homeUniversityName')}</h6>
                        <p>{univOrigin?.name}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('collegeDepartment')}</h6>
                        <p>{candidature?.facult_d_partement__c}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('coordinatorLastname')}</h6>
                        <p>{candidature?.Contact?.nom_du_coordinateur_institutionnel__c}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('coordinatorFirstname')}</h6>
                        <p>
                          {candidature?.Contact?.pr_nom_du_coordinateur_institutionnel__c}
                        </p>
                      </div>
                      <div className="inline-field">
                      <h6>{t('coordinatorMail')}</h6>
                        <p>
                          {candidature?.Contact?.email_coordinateur_institutionnel__c}
                        </p>
                      </div>
                      
                    </div>
                    <hr></hr>
                  </>
                )}
                {candidature?.Contact?.type_tech__c === 'Francais' && (
                  <>
                    <div className="parcours bac">
                      <h5>{t('bac')}</h5>
                      <div className="inline-field">
                        <h6>{t('bacType')}</h6>
                        <p>{bacData?.type_de_bac__c}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('bacOption')}</h6>
                        <p>{bacData?.option_du_bac__c}</p>
                      </div>
                      {bacData?.specialites_du_bac__c && (
                        <div className="inline-field">
                          <h6>{t('specialty')}</h6>
                          <p>{bacData?.specialites_du_bac__c}</p>
                        </div>
                      )}
                      <div className="inline-field">
                        <h6>{t('bacMention')}</h6>
                        <p>{bacData?.mention_du_bac__c}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('graduationYear')}</h6>
                        <p>{bacData?.annee_d_obtention_du_bac__c}</p>
                      </div>
                    </div>
                    <hr></hr>
                  </>
                )}
                <div className="parcours sup">
                  <h5>{t('higherEducation')}</h5>
                  {candidature?.Contact?.EducationHistorys?.filter(
                    (history) => history.type_de_diplome__c !== 'Baccalauréat'
                  ).map((history, index, row) => {
                    return (
                      <>
                        {candidature?.Contact?.type_tech__c === 'Francais' && (
                          <>
                            <p>
                              {t('otherDiploma')} {index + 1}
                            </p>
                            <div className="inline-field">
                              <h6>{t('schoolYear')}</h6>
                              <p>{history.annee_scolaire__c}</p>
                            </div>
                            <div className="inline-field">
                              <h6>{t('formationName')}</h6>
                              <p>{history.intitule_de_formation__c}</p>
                            </div>
                            <div className="inline-field">
                              <h6>{t('level')}</h6>
                              <p>{history.niveau__c}</p>
                            </div>
                            <div className="inline-field">
                              <h6>{t('speDom')}</h6>
                              <p>{history.specialite_domaine_fr__c}</p>
                            </div>
                            <div className="inline-field">
                              <h6>{t('appFollow')}</h6>
                              <p>
                                {history.formation_suivie_par_apprentissage__c}
                              </p>
                            </div>
                            <div className="inline-field">
                              <h6>{t('institution')}</h6>
                              <p>
                                {history.hed__educational_institution_name__c}
                              </p>
                            </div>
                            {history.ville_etablissement_fr__c && (
                              <div className="inline-field">
                                <h6>{t('city')}</h6>
                                <p>{history.ville_etablissement_fr__c}</p>
                              </div>
                            )}
                            {index + 1 !== row.length && <hr></hr>}
                          </>
                        )}
                        {candidature?.Contact?.type_tech__c ===
                          'International' && (
                          <>
                            <div className="inline-field">
                              <h6>{t('schoolYear')}</h6>
                              <p>{history.annee_scolaire__c}</p>
                            </div>
                            {history.hed__degree_earned__c !== 'Autre' ? (
                              <div className="inline-field">
                                <h6>{t('diploma')}</h6>
                                <p>{history.hed__degree_earned__c}</p>
                              </div>
                            ) : (
                              <div className="inline-field">
                                <h6>{t('otherDiploma')}</h6>
                                <p>{history.autre_diplome__c}</p>
                              </div>
                            )}
                            <div className="inline-field">
                              <h6>{t('speDom')}</h6>
                              <p>{history.specialite__c}</p>
                            </div>
                            <div className="inline-field">
                              <h6>{t('yearLevel')}</h6>
                              <p>{history.annee_niveau__c}</p>
                            </div>
                            <div className="inline-field">
                              <h6>{t('institution')}</h6>
                              <p>
                                {history.hed__educational_institution_name__c}
                              </p>
                            </div>
                            <div className="inline-field">
                              <h6>{t('average')}</h6>
                              <p>{history.moyenne__c}</p>
                            </div>
                            <div className="inline-field">
                              <h6>{t('mentionRank')}</h6>
                              <p>{history.classement__c}</p>
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
                <hr></hr>
                <div className="parcours langue">
                  <h5>{t('langSkill')}</h5>
                  {candidature?.Contact?.ContactLanguages?.map(
                    (language, index, row) => {
                      return (
                        <>
                          {language.langue1__c === 'Anglais' ? (
                            <>
                              <p>{t('english')}</p>
                              <div className="inline-field">
                                <h6>{t('level')}</h6>
                                <p>{language.hed__fluency__c}</p>
                              </div>
                              <div className="inline-field">
                                <h6>{t('englishCertif')}</h6>
                                <p>
                                  {language.certificat_officiel_de_langue__c}
                                </p>
                              </div>
                              <div className="inline-field">
                                <h6>{t('graduationYear')}</h6>
                                <p>{language.ann_e_d_obtention__c}</p>
                              </div>
                              <div className="inline-field">
                                <h6>{t('score')}</h6>
                                <p>{language.score__c}</p>
                              </div>
                            </>
                          ) : (
                            <>
                              {candidature?.Contact?.type_tech__c ===
                                'International' &&
                                language.langue1__c === 'Français' && (
                                  <>
                                    <p>{t('french')}</p>
                                    <div className="inline-field">
                                      <h6>{t('level')}</h6>
                                      <p>
                                        {
                                          candidature?.Contact
                                            ?.type_d_exp_rience__c
                                        }
                                      </p>
                                    </div>
                                    <div className="inline-field">
                                      <h6>{t('frenchCertif')}</h6>
                                      <p>
                                        {
                                          language.certificat_officiel_de_langue__c
                                        }
                                      </p>
                                    </div>
                                    <div className="inline-field">
                                      <h6>{t('graduationYear')}</h6>
                                      <p>{language.ann_e_d_obtention__c}</p>
                                    </div>
                                    <div className="inline-field">
                                      <h6>{t('score')}</h6>
                                      <p>{language.score__c}</p>
                                    </div>
                                  </>
                                )}
                              {language.langue1__c !== 'Anglais' &&
                                language.langue1__c !== 'Français' && (
                                  <>
                                    <p>{t('otherLanguages')}</p>
                                    <div className="inline-field">
                                      <h6>{t('language')}</h6>
                                      <p>{language.Languages?.name}</p>
                                    </div>
                                    <div className="inline-field">
                                      <h6>{t('level')}</h6>
                                      <p>{language.hed__fluency__c}</p>
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                          {index + 1 !== row.length && <hr></hr>}
                        </>
                      );
                    }
                  )}
                </div>
                <hr></hr>
                {candidature?.Contact?.ExperienceProfessionnelle && (
                  <>
                    <div className="parcours cursus">
                      <h5>{t('proExperiences')}</h5>
                      {candidature?.Contact?.ExperienceProfessionnelle?.map(
                        (exp, index, row) => {
                          return (
                            <>
                              <p>
                                {t('experienceNumber')} {index + 1}
                              </p>
                              <div className="inline-field">
                                <h6>{t('type')}</h6>
                                <p>{exp.type_d_exp_rience__c}</p>
                              </div>
                              <div className="inline-field">
                                <h6>{t('year')}</h6>
                                <p>{exp.annee_new__c}</p>
                              </div>
                              <div className="inline-field">
                                <h6>{t('duration')}</h6>
                                <p>{exp.duree__c}</p>
                              </div>
                              <div className="inline-field">
                                <h6>{t('employerName')}</h6>
                                <p>{exp.employeur__c}</p>
                              </div>
                              {candidature?.Contact?.type_tech__c ===
                                'Francais' && (
                                <div className="inline-field">
                                  <h6>{t('city')}</h6>
                                  <p>{exp.ville__c}</p>
                                </div>
                              )}
                              <div className="inline-field">
                                <h6>{t('country')}</h6>
                                <p>{exp.pays__c}</p>
                              </div>
                              <div className="inline-field">
                                <h6>{t('mission')}</h6>
                                <p>{exp.mission__c}</p>
                              </div>
                              {index + 1 !== row.length && <hr></hr>}
                            </>
                          );
                        }
                      )}
                    </div>
                  </>
                )}
                <hr></hr>
                <div className="parcours trips">
                  <h5>{t('foreignExperience')}</h5>

                  {candidature?.Contact?.SejourEtrangers?.map(
                    (trip, index, row) => {
                      return (
                        <>
                          <p>
                            {t('tripNumber')} {index + 1}
                          </p>
                          <div className="inline-field">
                            <h6>{t('year')}</h6>
                            <p>{trip.ann_e__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('duration')}</h6>
                            <p>{trip.duree__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('country')}</h6>
                            <p>{trip.pays__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('stayPurpose')}</h6>
                            <p>{trip.objet__c}</p>
                          </div>
                          {index + 1 !== row.length && <hr></hr>}
                        </>
                      );
                    }
                  )}
                </div>
                <hr></hr>

                <div className="parcours docs">
                  <h5>{t('relatedDoc')}</h5>
                  <div className="attachment">
                    <div className="atts">
                      <h6>{t('attachments')}</h6>
                      <div>
                        {candidature?.Contact?.Files?.length ||
                        candidature?.Files?.length ? (
                          getFiles().map((file) => {
                            return <p key={file.id_heroku__c}>{file.name}</p>;
                          })
                        ) : (
                          <i>{t('noAttachments')}</i>
                        )}
                      </div>
                    </div>
                    <div className="atts">
                      <div>
                        {candidature?.Contact?.Files?.length ||
                        candidature?.Files?.length ? (
                          <button
                            key="dl"
                            className="btn btn-outlined"
                            onClick={DownloadZIP}>
                            {t('download')}
                          </button>
                        ) : (
                          <br />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(
  withTranslation(['jury', 'form', 'common'])(OpenFolder)
);
