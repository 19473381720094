/**
 * This component displays the Cursus form for any type of candidate
 */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import UploadFile from '../../../../../components/UploadFile/UploadFile';
import { Contact } from '../../../../../services/api/Contact/interface';
import Select from 'react-select';

import './CursusForm.scss';
import { EducationHistory } from '../../../../../services/api/EducationHistory/interface';
import {
  FileToUpload,
  PieceComplementaire
} from '../../../../../services/api/Files/interface';
import FilesService from '../../../../../services/api/Files/FilesService';
import DisplayFiles from '../../../../../components/DisplayFiles/DisplayFiles';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import PicklistsService from '../../../../../services/api/Picklists/PicklistsService';
import { Picklist } from '../../../../../services/api/Picklists/interface';
import contactPicklist from '../../../../../assets/data/contact_picklist.json';
import {
  checkRequested,
  formatUnicode
} from '../../../../../utils/validator/validator';
import DisplayBlobFiles from '../../../../../components/DisplayBlobFiles/DisplayBlobFiles';
import FacilitiesService from '../../../../../services/api/Facilities/FacilitiesService';
import { Facilities } from '../../../../../services/api/Facilities/interface';

interface ContainerProps {
  cursus: EducationHistory;
  contactdata: Contact;
  onChange: (data: any, next: boolean) => void;
  getContact?: () => void;
  handleDelete?: (data: EducationHistory) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const CursusForm: React.FC<IProps> = (props) => {
  const { t, cursus, onChange, contactdata, getContact, handleDelete } = props;

  const [form, setForm] = useState<EducationHistory>(cursus);
  const [contact, setContact] = useState<Contact>(contactdata);
  const [years, setYears] = useState<string[]>([]);
  const [schoolInput, setSchoolInput] = useState<string>(
    form.hed__educational_institution_name__c || ''
  );
  const [fileList, setFileList] = useState<FileToUpload[]>([]);

  const [fileObject, setFileObject] = useState<any>({});
  const [educationHistoryPicklist, setEducationHistoryPicklist] =
    useState<Picklist>();
  const [schoolList, setSchoolList] = useState<Facilities[]>([]);
  const required: any = {
    Francais: [
      'annee_scolaire__c',
      'intitule_de_formation__c',
      'annee_scolaire__c',
      'formation_suivie_par_apprentissage__c',
      'hed__educational_institution_name__c'
      // 'ville_etablissement_fr__c'
    ],
    International: [
      'specialite__c',
      'annee_niveau__c',
      'hed__educational_institution_name__c',
      'langue_d_enseignement__c',
      'pays_de_l_tablissement__c'
    ],
    Echange: [
      'specialite__c',
      'annee_niveau__c',
      'en_cours_ou_validee__c',
      'hed__educational_institution_name__c',
      'langue_d_enseignement__c',
      'pays_de_l_tablissement__c'
    ]
  };

  const exchangeDiploma = [
    {
      label: '',
      value: ''
    },
    {
      label: 'Bachelor',
      value: 'Bachelor'
    },
    {
      label: 'Master',
      value: 'Master'
    },
    {
      label: 'Autre',
      value: 'Other'
    }
  ];
  const [niveaux, setNiveaux] = useState<string[]>([
    '',
    '1è année',
    '2è année',
    '3è année',
    '4è année',
    '5è année'
  ]);
  const [schools, setSchools] = useState<any[]>([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    getEducationHistoryPicklist();
    getFacilities();
    buildYears();
  }, []);

  useEffect(() => {
    setForm(cursus);
  }, [cursus.index, cursus.cursusId, cursus.id_heroku__c]);

  useEffect(() => {
    if (contact.type_tech__c) {
      let list = [...required[contact.type_tech__c]];
      if (form.noInstitution) {
        list.push('ville_etablissement_fr__c');
      }
      if (
        form.intitule_de_formation__c !== 'Je suis déjà étudiant JUNIA' &&
        contact.type_tech__c !== 'Echange'
      ) {
        list.push('niveau__c');
        list.push('specialite_domaine_fr__c');
      }
      if (
        form.intitule_de_formation__c === 'Je suis déjà étudiant JUNIA' &&
        contact.type_tech__c === 'Francais'
      ) {
        list.push('formation_en_cours_junia__c');
      }
      let canNext = checkRequested(list, contact);
      onChange({ data: contact }, canNext);
    }
  }, []);

  useEffect(() => {
    if (form.hed__educational_institution_name__c) {
      setSchoolInput(form.hed__educational_institution_name__c);
    }
  }, [form.noInstitution]);

  const getFacilities = async () => {
    try {
      const facilitiesResponse = await FacilitiesService.getFacilities();
      let facilities: Facilities[] = facilitiesResponse.data;
      setSchoolList(facilities);
    } catch (err) {
      console.error(err);
    }
  };

  const getEducationHistoryPicklist = async () => {
    try {
      const hedEducationHistoryPicklistResponse =
        await PicklistsService.getHedEducationHistoryPicklists();
      let hedEducationHistoryPicklist: Picklist =
        hedEducationHistoryPicklistResponse.data;
      setEducationHistoryPicklist(hedEducationHistoryPicklist);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (e: any): void => {
    let value = e.target.value;
    let check = ['noInstitution'];
    let partenaire = form.is_partner__c;

    if (e.target.name === 'annee_scolaire__c') {
      const selectedSchool = schoolList.find(
        (school) => school.sfid === form.facility__c
      );
      partenaire = selectedSchool?.partenaire__c === e.target.value;
    }

    let newForm = {
      ...form
    };
    if (check.includes(e.target.name)) {
      value = e.target.checked;
    }
    if (e.target.name === 'intitule_de_formation__c') {
      newForm.specialite_domaine_fr__c = '';
      newForm.niveau__c = '';
    }

    newForm = {
      ...newForm,
      [e.target.name]: value,
      is_partner__c: partenaire ? true : false
    };
    setForm(newForm);
    if (contact?.type_tech__c) {
      // let canNext = checkRequested(required[contact.type_tech__c], newForm);
      onChange({ data: newForm, file: fileObject }, true);
    }
  };

  const onDelete = () => {
    if (handleDelete) {
      handleDelete(form);
    }
  };

  const buildYears = () => {
    let yearList = [];
    let year = moment().add(1, 'years').format('YYYY');
    for (let index = 1; index <= 10; index++) {
      let previousYear = moment(year).subtract(index, 'years').format('YYYY');
      let value = `${previousYear}-${moment(year)
        .subtract(index - 1, 'years')
        .format('YY')}`;
      yearList.push(value);
    }
    setYears(yearList);
  };

  const buildSchools = (value: string) => {
    let filteredSchools = _.chain([...schoolList])
      .filter((c: any) => {
        // findInArray(c.name, value);
        let fullValue = `${c.ville__c} ${c.code_postal_ville__c} ${c.name}`;
        return (
          (c && c.name.toLowerCase().includes(value.toLowerCase())) ||
          (c &&
            c.code_postal_ville__c &&
            c.code_postal_ville__c
              .toLowerCase()
              .includes(value.toLowerCase())) ||
          (c &&
            c.ville__c &&
            c.ville__c.toLowerCase().includes(value.toLowerCase())) ||
          fullValue.toLowerCase().includes(value)
        );
      })
      .sortBy('ville__c', 'code_postal_ville__c', 'name')
      .take(20)
      .map((school) => {
        return {
          value: school.sfid,
          label: `${school.ville__c} ${school.code_postal_ville__c} ${school.name}`,
          partenaire: school.partenaire__c
        };
      })
      .value();
    setSchools(filteredSchools);
  };

  const handleKeyDownSchool = (e: any) => {
    setSchoolInput(e);
    buildSchools(e);
  };

  const handleFileChange = (files: File[], type: string) => {
    let list = [...fileList];
    let found = _.findIndex(list, (f) => f.type === type);
    if (found >= 0) {
      let data = [...list[found].files, ...files];
      list.splice(found, 1, { type: type, files: data });
    } else {
      list.push({ type: type, files: files });
    }

    setFileList(list);
    if (contact.type_tech__c) {
      // let canNext = checkRequested(required[contact.type_tech__c], form);
      onChange({ data: form, files: list }, true);
    }
  };

  const handleFileDelete = (file: any, type: string, fileType: string) => {
    switch (fileType) {
      case 'uploaded':
        if (file?.id_heroku__c && contact?.id_heroku__c) {
          FilesService.removeFile(
            file?.id_heroku__c,
            contact?.id_heroku__c
          ).then((res) => {
            const updatedForm = _.update(form, 'Files', (files) =>
              _.filter(files, (f) => f.id_heroku__c !== file.id_heroku__c)
            );
            setForm(updatedForm);
            if (getContact) {
              getContact();
              // setFileList([])
            }
          });
        }
        break;
      case 'blob':
        var liste = [...fileList];
        var index = _.findIndex(liste, (f) => f.type === file.type);
        var fileIndex = _.findIndex(
          liste[index].files,
          (f: File) =>
            f.lastModified === file.files[0].lastModified &&
            f.name === file.files[0].name
        );
        var array: File[] = liste[index].files;
        array.splice(fileIndex, 1);
        liste[index].files = array;
        setFileList(liste);
        break;
    }
  };

  const findNiveau = () => {
    if (form.intitule_de_formation__c && educationHistoryPicklist) {
      let niveau = educationHistoryPicklist.picklistFieldValues['Niveau__c'];
      let validFor: any = niveau.controllerValues;
      let intitule = form.intitule_de_formation__c;
      let data = _.filter(niveau.values, (n: any) =>
        n.validFor.includes(validFor[intitule])
      );
      return data;
    }
  };
  const findSpecialite = () => {
    if (form.intitule_de_formation__c && educationHistoryPicklist) {
      let specialites =
        educationHistoryPicklist.picklistFieldValues[
          'Specialite_domaine_FR__c'
        ];
      let validFor: any = specialites.controllerValues;
      let intitule = form.intitule_de_formation__c;
      let data = _.filter(specialites.values, (n: any) =>
        n.validFor.includes(validFor[intitule])
      );
      return data;
    }
  };

  const findMax = (
    type: string,
    max: number,
    files: PieceComplementaire[] | undefined
  ) => {
    if (files) {
      let foundCurrent = _.find([...fileList], (f) => f.type === type);
      let foundSave = _.filter([...files], (f) => f.type__c === type);
      let currentLength = foundCurrent ? foundCurrent.files.length : 0;
      let saveLength = foundSave ? foundSave.length : 0;
      return currentLength + saveLength;
    } else {
      return 0;
    }
  };

  const handleEducationnalInstitutionChange = (value: any) => {
    const partenaire = form.annee_scolaire__c === value.partenaire;

    let newForm = {
      ...form,
      hed__educational_institution_name__c: value.label,
      is_partner__c: partenaire,
      facility__c: value.value
    };

    setForm(newForm);

    if (contact.type_tech__c) {
      onChange({ data: newForm, file: fileObject }, true);
    }
  };
  return (
    <div>
      {/* Autre diplome */}
      {contact.type_tech__c !== 'Francais' && (
        <>
          {contact.type_tech__c === 'International' ? (
            <>
              <div>
                <h6 className="form-h6">
                  {t('schoolYear')} <span className="form-required">*</span>
                </h6>
                <select
                  name="annee_scolaire__c"
                  value={form.annee_scolaire__c}
                  onChange={handleChange}>
                  <option value={''}></option>
                  {educationHistoryPicklist &&
                    educationHistoryPicklist.picklistFieldValues[
                      'Annee_scolaire__c'
                    ].values.map((p: any) => {
                      if (p && p.value) {
                        return (
                          <option key={p.value} value={p.value}>
                            {p.label}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>
              <div>
                <h6 className="form-h6">
                  {t('curriculum')} <span className="form-required">*</span>
                </h6>
                <select
                  name="intitul_de_formation_intl__c"
                  value={form.intitul_de_formation_intl__c}
                  onChange={handleChange}>
                  <option value={''}></option>
                  {educationHistoryPicklist &&
                    educationHistoryPicklist.picklistFieldValues[
                      'Intitul_de_formation_INTL__c'
                    ].values.map((value: any) => {
                      if (i18n.language === 'fr') {
                        return (
                          <option
                            key={value.value}
                            value={value.value.replace(/&#39;/g, "'")}>
                            {value.label.replace(/&#39;/g, "'")}
                          </option>
                        );
                      }
                      if (i18n.language === 'en') {
                        return (
                          <option
                            key={value.value}
                            value={value.value.replace(/&#39;/g, "'")}>
                            {value.value.replace(/&#39;/g, "'")}
                          </option>
                        );
                      }
                    })}
                </select>
              </div>
            </>
          ) : (
            <>
              <div>
                <h6 className="form-h6">{t('otherDiploma')}</h6>
                <select
                  name="hed__degree_earned__c"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={form.hed__degree_earned__c}>
                  {i18n.language === 'fr' &&
                    exchangeDiploma.map((diploma) => (
                      <option key={diploma.value} value={diploma.value}>
                        {diploma.label}
                      </option>
                    ))}
                  {i18n.language === 'en' &&
                    exchangeDiploma.map((diploma) => (
                      <option key={diploma.value} value={diploma.value}>
                        {diploma.value}
                      </option>
                    ))}
                </select>
              </div>
              {form.hed__degree_earned__c === 'Other' && (
                <div>
                  <input
                    placeholder={t('otherDiploma')}
                    name="autre_diplome__c"
                    onChange={handleChange}
                    value={form.autre_diplome__c}
                  />
                </div>
              )}
            </>
          )}
          <div>
            {i18n.language === 'fr' && (
              <h6 className="form-h6">
                {t('specialty')} / {t('domain')}{' '}
                <span className="form-required">*</span>
              </h6>
            )}
            {i18n.language === 'en' && (
              <h6 className="form-h6">
                {t('major')} <span className="form-required">*</span>
              </h6>
            )}
            <input
              name="specialite__c"
              onChange={handleChange}
              value={form.specialite__c}
            />
          </div>
          <div>
            <h6 className="form-h6">
              {t('level')} <span className="form-required">*</span>
            </h6>
            <select
              name="annee_niveau__c"
              onChange={handleChange}
              value={form.annee_niveau__c}>
              <option value=""></option>
              {educationHistoryPicklist &&
                educationHistoryPicklist?.picklistFieldValues[
                  'Annee_Niveau__c'
                ].values.map((value: any) => {
                  if (i18n.language === 'fr') {
                    return (
                      <option key={value.value} value={value.value}>
                        {value.label}
                      </option>
                    );
                  }
                  if (i18n.language === 'en') {
                    return (
                      <option key={value.value} value={value.value}>
                        {value.value}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
          {contact.type_tech__c === 'Echange' && (
            <div className="custom-checkbox">
              <h6 className="form-h6">
                {t('currentOrValid')} <span className="form-required">*</span>
              </h6>
              <div className="custom-checkbox">
                <input
                  className="custom-check"
                  id={`validyes-${form.cursusId}`}
                  type="checkbox"
                  name="en_cours_ou_validee__c"
                  onChange={handleChange}
                  value="Validée"
                  checked={form.en_cours_ou_validee__c === 'Validée'}
                />
                <label
                  htmlFor={`validyes-${form.cursusId}`}
                  className={
                    form.en_cours_ou_validee__c === 'Validée' ? 'selected' : ''
                  }>
                  {t('validated')}
                </label>
                <input
                  className="custom-check"
                  id={`validno-${form.cursusId}`}
                  type="checkbox"
                  name="en_cours_ou_validee__c"
                  onChange={handleChange}
                  value="En cours"
                  checked={form.en_cours_ou_validee__c === 'En cours'}
                />
                <label
                  htmlFor={`validno-${form.cursusId}`}
                  className={
                    form.en_cours_ou_validee__c === 'En cours' ? 'selected' : ''
                  }>
                  {t('inProgress')}
                </label>
              </div>
            </div>
          )}
          <div>
            <h6 className="form-h6">
              {t('institution')} <span className="form-required">*</span>
            </h6>
            <input
              value={form.hed__educational_institution_name__c}
              name="hed__educational_institution_name__c"
              onChange={handleChange}
              type="text"
            />
          </div>
          <div>
            <h6 className="form-h6">
              {t('languageEducation')} <span className="form-required">*</span>
            </h6>
            <input
              name="langue_d_enseignement__c"
              value={form.langue_d_enseignement__c}
              onChange={handleChange}
            />
          </div>
          <div>
            <h6 className="form-h6">
              {t('country')} <span className="form-required">*</span>
            </h6>
            <select
              name="pays_de_l_tablissement__c"
              value={form.pays_de_l_tablissement__c}
              onBlur={handleChange}
              onChange={handleChange}>
              <option value={''}></option>

              {i18n.language === 'fr' &&
                _.chain(
                  contactPicklist.picklistFieldValues['Liste_pays_FR__c'].values
                )
                  .map((p) => {
                    if (p && p.value) {
                      return (
                        <option
                          value={p.value.replace(/&#39;/g, "'")}
                          key={p.label}>
                          {' '}
                          {p.label.replace(/&#39;/g, "'")}{' '}
                        </option>
                      );
                    }
                  })
                  .value()}
              {i18n.language === 'en' &&
                _.chain(
                  contactPicklist.picklistFieldValues['Liste_pays_FR__c'].values
                )
                  .map((p) => {
                    if (p && p.value) {
                      return (
                        <option
                          value={p.value.replace(/&#39;/g, "'")}
                          key={p.value}>
                          {' '}
                          {p.value.replace(/&#39;/g, "'")}{' '}
                        </option>
                      );
                    }
                  })
                  .value()}
            </select>
          </div>
          {contact.type_tech__c === 'Echange' && (
            <div>
              <h6 className="form-h6">{t('average')}</h6>
              <input
                name="moyenne__c"
                value={form.moyenne__c}
                onChange={handleChange}
              />
            </div>
          )}
          <div>
            <h6 className="form-h6">{t('mentionRank')}</h6>
            <input
              name="classement__c"
              value={form.classement__c}
              onChange={handleChange}
            />
          </div>
          <div>
            <h6 className="form-h6">
              {t('ifGetDiploma')}{' '}
              {/* {contact?.inscription_tech__c === 'Terminée' && (
                <span className="form-required">*</span>
              )} */}
            </h6>
            <UploadFile
              max={2}
              current={findMax('degree', 2, form.Files)}
              onChange={(data) => handleFileChange(data, 'degree')}
              type="pdf"></UploadFile>
            <DisplayBlobFiles
              files={fileList}
              type="degree"
              onDelete={(data) =>
                handleFileDelete(data, 'degree', 'blob')
              }></DisplayBlobFiles>
            <DisplayFiles
              files={form.Files}
              type="degree"
              onDelete={(data) =>
                handleFileDelete(data, 'degree', 'uploaded')
              }></DisplayFiles>
            {_.map(fileList, (file: File, index: Number) => {
              return <p key={`degree-${index}`}>{file.name}</p>;
            })}
          </div>
          <div>
            <h6 className="form-h6">
              {t('recordTranscript')}
              {contact?.inscription_tech__c === 'Terminée' && (
                <span className="form-required">*</span>
              )}
              {contact?.type_tech__c !== 'International' ? (
                <Tooltip message={t('tooltip.recordTranscriptInt')}></Tooltip>
              ) : (
                <Tooltip message={t('tooltip.bacTranscriptInt')}></Tooltip>
              )}
            </h6>
            <UploadFile
              max={2}
              current={findMax('grades', 2, form.Files)}
              onChange={(data) => handleFileChange(data, 'grades')}
              type="pdf"></UploadFile>
            <DisplayBlobFiles
              files={fileList}
              type="grades"
              onDelete={(data) =>
                handleFileDelete(data, 'grades', 'blob')
              }></DisplayBlobFiles>
            <DisplayFiles
              files={form.Files}
              type="grades"
              onDelete={(data) =>
                handleFileDelete(data, 'grades', 'uploaded')
              }></DisplayFiles>
          </div>
          {handleDelete && (
            <div className="delete-line">
              <button className="btn-underline" onClick={onDelete}>
                <span>{t('word.delete')}</span>
              </button>
            </div>
          )}
          <p className="info-text">{t('completedAcademicCurriculum')}</p>
          <p className="info-text">{t('addAcademicYear')}</p>
          <hr></hr>
        </>
      )}

      {/* Enseignement supérieur */}
      {/* French students */}
      {contact.type_tech__c === 'Francais' && (
        <>
          <div>
            <h6 className="form-h6">
              {t('schoolYear')} <span className="form-required">*</span>
            </h6>
            <select
              name="annee_scolaire__c"
              value={form.annee_scolaire__c}
              onChange={handleChange}>
              <option value={''}></option>
              {educationHistoryPicklist &&
                _.chain(
                  educationHistoryPicklist.picklistFieldValues[
                    'Annee_scolaire__c'
                  ].values
                )
                  .map((p) => {
                    if (p && p.value) {
                      return (
                        <option key={p.value} value={p.value}>
                          {p.label}
                        </option>
                      );
                    }
                  })
                  .value()}
            </select>
          </div>
          <div>
            <h6 className="form-h6">
              {t('formationName')} <span className="form-required">*</span>
            </h6>
            <select
              name="intitule_de_formation__c"
              value={form.intitule_de_formation__c}
              onChange={handleChange}>
              <option value={''}></option>
              {educationHistoryPicklist &&
                _.chain(
                  educationHistoryPicklist.picklistFieldValues[
                    'Intitule_de_formation__c'
                  ].values
                )
                  .map((p) => {
                    if (p && p.value) {
                      let value = formatUnicode(p.value);
                      return (
                        <option key={value} value={value}>
                          {' '}
                          {value}{' '}
                        </option>
                      );
                    }
                  })
                  .value()}
            </select>
          </div>
          {form.intitule_de_formation__c != 'Je suis déjà étudiant JUNIA' ? (
            <div>
              <h6 className="form-h6">
                {t('level')} <span className="form-required">*</span>
              </h6>
              <select
                name="niveau__c"
                value={form.niveau__c}
                onChange={handleChange}>
                <option value={''}></option>
                {form.intitule_de_formation__c &&
                  educationHistoryPicklist &&
                  _.chain(findNiveau())
                    .map((p: any) => {
                      if (p && p.value) {
                        let value = formatUnicode(p.value);
                        return (
                          <option key={value} value={value}>
                            {' '}
                            {value}{' '}
                          </option>
                        );
                      }
                    })
                    .value()}
              </select>
            </div>
          ) : (
            <div>
              <h6 className="form-h6">
                {t('specify')} <span className="form-required">*</span>
              </h6>
              <textarea
                placeholder={t('specify')}
                name="formation_en_cours_junia__c"
                onChange={handleChange}
                value={form.formation_en_cours_junia__c}
              />
            </div>
          )}
          {form.intitule_de_formation__c != 'Je suis déjà étudiant JUNIA' && (
            <div>
              <h6 className="form-h6">
                {t('speDom')} <span className="form-required">*</span>
              </h6>
              <select
                name="specialite_domaine_fr__c"
                value={form.specialite_domaine_fr__c}
                onChange={handleChange}>
                <option value={''}></option>
                {educationHistoryPicklist &&
                  _.chain(findSpecialite())
                    .map((p: any) => {
                      if (p && p.value) {
                        let value = formatUnicode(p.value);
                        return (
                          <option key={value} value={value}>
                            {' '}
                            {value}{' '}
                          </option>
                        );
                      }
                    })
                    .value()}
              </select>
            </div>
          )}
          <div>
            <h6 className="form-h6">
              {t('appFollow')} <span className="form-required">*</span>
            </h6>
            <select
              name="formation_suivie_par_apprentissage__c"
              value={form.formation_suivie_par_apprentissage__c}
              onChange={handleChange}>
              <option value={''}></option>
              {educationHistoryPicklist &&
                _.chain(
                  educationHistoryPicklist.picklistFieldValues[
                    'Formation_suivie_par_apprentissage__c'
                  ].values
                )
                  .map((p) => {
                    if (p && p.value) {
                      return (
                        <option key={p.value} value={p.value}>
                          {' '}
                          {p.label}{' '}
                        </option>
                      );
                    }
                  })
                  .value()}
            </select>
          </div>
          {!form.noInstitution && (
            <div>
              <h6 className="form-h6">
                {t('institution')} <span className="form-required">*</span>
              </h6>
              {/* <input
              name="etablissement_universite_fr__c"
              value={form.etablissement_universite_fr__c}
              onChange={handleChange}
            /> */}
              <Select
                className="select-react"
                name="hed__educational_institution_name__c"
                options={schools}
                inputValue={schoolInput}
                // filterOption={filtreSchools}
                isMulti={false}
                onInputChange={handleKeyDownSchool}
                onChange={handleEducationnalInstitutionChange}
                placeholder={t('institution')}
              />
            </div>
          )}
          <div className="custom-checkbox-box">
            <p>
              <input
                value="Oui"
                type="checkbox"
                name="noInstitution"
                checked={form.noInstitution ? true : false}
                onChange={handleChange}
              />
              {t('instiNotFound')}
            </p>
          </div>
          {form.noInstitution && (
            <>
              <div>
                <h6 className="form-h6">
                  {t('institution')} <span className="form-required">*</span>
                </h6>
                <input
                  value={form.hed__educational_institution_name__c}
                  name="hed__educational_institution_name__c"
                  onChange={handleChange}
                  type="text"
                />
              </div>
              <div>
                <h6 className="form-h6">
                  {t('cityUniv')} <span className="form-required">*</span>
                </h6>
                <input
                  name="ville_etablissement_fr__c"
                  value={form.ville_etablissement_fr__c}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
          <div>
            <h6 className="form-h6">
              {t('ifGetDiploma')}{' '}
              {/* {contact?.inscription_tech__c === 'Terminée' && (
                <span className="form-required">*</span>
              )} */}
            </h6>
            <UploadFile
              max={2}
              current={findMax('degree', 2, form.Files)}
              onChange={(data) => handleFileChange(data, 'degree')}
              type="pdf"></UploadFile>
            <DisplayBlobFiles
              files={fileList}
              type="degree"
              onDelete={(data) =>
                handleFileDelete(data, 'degree', 'blob')
              }></DisplayBlobFiles>
            <DisplayFiles
              files={form.Files}
              type="degree"
              onDelete={(data) =>
                handleFileDelete(data, 'degree', 'uploaded')
              }></DisplayFiles>
          </div>
          <div>
            <h6 className="form-h6">
              {t('recordTranscript')}
              {contact?.inscription_tech__c === 'Terminée' && (
                <span className="form-required">*</span>
              )}
              <Tooltip message={t('tooltip.recordTranscript')}></Tooltip>
            </h6>
            <UploadFile
              max={2}
              current={findMax('grades', 2, form.Files)}
              onChange={(data) => handleFileChange(data, 'grades')}
              type="pdf"></UploadFile>
            <DisplayBlobFiles
              files={fileList}
              type="grades"
              onDelete={(data) =>
                handleFileDelete(data, 'grades', 'blob')
              }></DisplayBlobFiles>
            <DisplayFiles
              files={form.Files}
              type="grades"
              onDelete={(data) =>
                handleFileDelete(data, 'grades', 'uploaded')
              }></DisplayFiles>
          </div>
          {handleDelete && (
            <div className="delete-line">
              <button className="btn-underline" onClick={onDelete}>
                <span>{t('word.delete')}</span>
              </button>
            </div>
          )}
          <hr></hr>
        </>
      )}
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(CursusForm)
);
