/**
 * This component list all the applications/canditatures related to a Jury
 */
import React, { createElement, useEffect, useState } from 'react';
import './JuryList.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import List from '@mui/material/List/List';
import { ListItem, ListItemText } from '@mui/material';
import Folder from './components/Folder/Folder';
import Interview from './components/Interview/Interview';
import { Contact } from '../../services/api/Contact/interface';
import { me } from '../../services/api/Contact/interface';
import AuthService from '../../services/api/Auth/AuthService';
import JuryService, {
  ApplicationsResponse
} from '../../services/api/Jury/Jury';
import Spinner from '../../utils/Spinner/Spinner';
import Alert from '../../utils/Alert/Alert';

interface ContainerProps {
  useContact: Contact;
}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const JuryList: React.FC<IProps> = (props) => {
  const { t, useContact } = props;
  const [candidatures, setCandidatures] = useState<ApplicationsResponse[]>([]);
  const [candidaturesFiltered, setCandidaturesFiltered] = useState<
    ApplicationsResponse[]
  >([]);

  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  Spinner.spinner$.subscribe((s) => s !== loading && setLoading(s));

  const [me, setMe] = useState<me>();
  const [selected, setSelected] = useState<any>({});
  const [search, setSearch] = useState<string>();
  const [contact, setContact] = useState<Contact>(useContact);
  const [compareFn, setCompareFn] =
    useState<() => (a: ApplicationsResponse, b: ApplicationsResponse) => any>();
  const [liste, setListe] = useState<any[]>([
    {
      name: 'folder',
      component: Folder
    },
    {
      name: 'interview',
      component: Interview
    }
  ]);

  const order = [
    {
      key: 'orderBy',
      value: '',
      view: ['folder', 'interview']
    },
    {
      key: 'frCandidate',
      value: 'frCandidate',
      view: ['folder', 'interview']
    },
    {
      key: 'intlCandidate',
      value: 'intlCandidate',
      view: ['folder', 'interview']
    },
    {
      key: 'echangeCandidate',
      value: 'echangeCandidate',
      view: ['folder', 'interview']
    },
    {
      key: 'toProcessFilter',
      value: 'importanceFolder',
      view: ['folder']
    },
    {
      key: 'toProcessFilter',
      value: 'importanceItw',
      view: ['interview']
    },
    {
      key: 'proceeded',
      value: 'proceededFolder',
      view: ['folder']
    },
    {
      key: 'proceeded',
      value: 'proceededItw',
      view: ['interview']
    },
    {
      key: 'status',
      value: 'statut__c',
      view: ['folder', 'interview']
    },
    {
      key: 'alphabeticalOrder',
      value: 'alphabeticalOrder',
      view: ['folder', 'interview']
    },
    {
      key: 'date',
      value: 'date_jury_dossier__c',
      view: ['folder']
    },
    {
      key: 'interviewDate',
      value: 'date_jury_entretien__c',
      view: ['interview']
    }
  ];

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (me?.id_heroku__c) {
      let item = liste[0];
      setSelected(item);
      getApplications();
    }
  }, [me]);

  useEffect(() => {
    resetApplicationFilter();
  }, [candidatures]);

  useEffect(() => {}, [compareFn]);

  const getMe = async (): Promise<void> => {
    try {
      Spinner.setSpinner(true);
      setMe((await AuthService.me())?.data);
    } catch (err) {
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
      }
    }
  };

  const getApplications = async (): Promise<void> => {
    try {
      if (me?.id_heroku__c) {
        JuryService.getApplications(me?.id_heroku__c)
          .then((res) => {
            console.log(
              '🚀 ~ file: JuryList.tsx ~ line 111 ~ JuryService.getApplications ~ res',
              res
            );
            Spinner.setSpinner(false);
            setCandidatures(res.data);
          })
          .catch((err) => {
            Spinner.setSpinner(false);
            console.log(
              '🚀 ~ file: JuryList.tsx ~ line 113 ~ JuryService.getApplications ~ err',
              err.response
            );
          });
      }
    } catch (err) {
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
      }
    }
  };

  const resetApplicationFilter = () => {
    setCandidaturesFiltered(candidatures);
  };

  const handleClick = (item: any): void => {
    setSelected(item);
  };

  const filterCandidate = (e?: React.ChangeEvent<HTMLInputElement>): void => {
    let filter, filtered;
    if (e) {
      filter = e.target.value;
      setSearch(filter);
    }
    const lowerSearch = filter?.toLocaleLowerCase() || '';
    if (lowerSearch) {
      filtered = candidatures.filter(
        (c) =>
          c.Contact?.firstname?.toLocaleLowerCase()?.includes(lowerSearch) ||
          c.Contact?.lastname?.toLocaleLowerCase()?.includes(lowerSearch) ||
          c.Contact?.email?.toLocaleLowerCase()?.includes(lowerSearch) ||
          c.Formation?.name?.toLocaleLowerCase()?.includes(lowerSearch) ||
          c.name?.includes(lowerSearch) ||
          `${c.Contact?.firstname?.toLocaleLowerCase()} ${c.Contact?.lastname?.toLocaleLowerCase()}`?.includes(
            lowerSearch
          )
      );
    }
    setCandidaturesFiltered(
      (filtered ?? candidatures).sort((a, b) => {
        let id1 = a.id ?? 0;
        let id2 = b.id ?? 0;
        return id2 - id1;
      })
    );
  };

  const handleFilter = (e: any): void => {
    resetApplicationFilter();
    const value = e.target.value;
    let filteredCandidatureList;

    // Filter proceeded application folder
    if (value === 'proceededFolder') {
      filteredCandidatureList = candidatures.filter((candidature) => {
        if (
          candidature.signature_du_correcteur__c ||
          candidature.signature_jury_dossier__c
        ) {
          return true;
        }
        return false;
      });
      setCandidaturesFiltered(filteredCandidatureList);
    }

    // Filter proceeded application interview
    if (value === 'proceededItw') {
      filteredCandidatureList = candidatures.filter(
        (candidature) => candidature.signature_signature_jury_entretien__c
      );
      setCandidaturesFiltered(filteredCandidatureList);
    }

    // Filter candidate type France
    if (value === 'frCandidate') {
      filteredCandidatureList = candidatures.filter(
        (candidature) => candidature.Contact?.type_tech__c === 'Francais'
      );
      setCandidaturesFiltered(filteredCandidatureList);
    }

    // Filter candidate type International
    if (value === 'intlCandidate') {
      filteredCandidatureList = candidatures.filter(
        (candidature) => candidature.Contact?.type_tech__c === 'International'
      );
      setCandidaturesFiltered(filteredCandidatureList);
    }

    // Filter candidate type Echange
    if (value === 'echangeCandidate') {
      filteredCandidatureList = candidatures.filter(
        (candidature) => candidature.Contact?.type_tech__c === 'Echange'
      );
      setCandidaturesFiltered(filteredCandidatureList);
    }

    // Filter to process application folder
    if (value === 'importanceFolder') {
      const importantCandidatureList = candidatures.filter(
        (candidature) =>
          candidature.statut__c === 'Etude du dossier' &&
          ((candidature.Contact?.type_tech__c === 'International' || candidature.Contact?.type_tech__c === 'Echange')
            ? !candidature.signature_jury_dossier__c
            : !candidature.signature_du_correcteur__c)
      );
      setCandidaturesFiltered(
        importantCandidatureList.sort(
          (a: any, b: any) => a.date_jury_dossier__c - b.date_jury_dossier__c
        )
      );
    } 
    // Filter to process application interview
    else if (value === 'importanceItw') {
      const importantCandidatureList = candidatures.filter(
        (candidature) =>
          (candidature.statut__c === 'Entretien' || candidature.statut__c === 'Entretien planifié' || candidature.statut__c === 'Convoqué Entretien') &&
          !candidature.signature_signature_jury_entretien__c
      );
      setCandidaturesFiltered(
        importantCandidatureList.sort(
          (a: any, b: any) => a.date_jury_entretien__c - b.date_jury_entretien__c
        )
      );
    } else {
      const filteredCandidatures = candidatures.sort((a: any, b: any) => {
        let paramA, paramB;
        // If selected filter is alphabetical order, sort by lastname & firstname
        if (value === 'alphabeticalOrder') {
          paramA = a.Contact?.['lastname']?.toLocaleLowerCase() + a.Contact?.['firstname']?.toLocaleLowerCase() ?? 0;
          paramB = b.Contact?.['lastname']?.toLocaleLowerCase() + b.Contact?.['firstname']?.toLocaleLowerCase() ?? 0;
        } else {
          paramA = a[value]?.toLocaleLowerCase() ?? 0;
          paramB = b[value]?.toLocaleLowerCase() ?? 0;
        }
        return paramA > paramB ? 1 : paramA < paramB ? -1 : 0;
      });
      setCandidaturesFiltered((prev: any) => {
        return [filteredCandidatures, ...prev];
      });
    }
  };

  return (
    <div>
      {!loading && (
        <div className="jury-list general-container">
          <div className="jury-header">
            <div className="left">
              <p>
                {selected.name === 'folder'
                  ? t('toProcess')
                  : t('ItwToProcess')}
              </p>
              <h3>
                {selected.name === 'folder'
                  ? candidatures?.filter(
                      (c) =>
                        c.jury_dossier__c === me?.sfid &&
                        c.statut__c === 'Etude du dossier' &&
                        ((c.Contact?.type_tech__c === 'International'||c.Contact?.type_tech__c === 'Echange')
                          ? !c.signature_jury_dossier__c
                          : !c.signature_du_correcteur__c)
                    )?.length ?? 0
                  : candidatures?.filter(
                      (c) =>
                        (c.membre_jury__c === me?.sfid ||
                          c.president_jury__c === me?.sfid ||
                          c.membre_jury_compl_mentaire__c === me?.sfid) &&
                        (c.statut__c === 'Convoqué Entretien' ||
                          c.statut__c === 'Entretien' ||
                          c.statut__c === 'Entretien planifié') &&
                        !c.signature_signature_jury_entretien__c
                    )?.length ?? 0}
              </h3>
            </div>
          </div>
          <div className="jury-table">
            <div className="jury-switch">
              <List className="switch-menu">
                {liste.map((item) => (
                  <ListItem
                    button={false}
                    key={item.text}
                    onClick={() => handleClick(item)}
                    className={selected == item ? 'active' : ''}>
                    <ListItemText className="tab-item" primary={t(item.name)} />
                  </ListItem>
                ))}
              </List>

              <div className="jury-filters">
                <input
                  type="search"
                  value={search}
                  placeholder={t('find')}
                  onChange={filterCandidate}
                />
                <select onChange={handleFilter}>
                  {order.map(
                    (o) =>
                      o.view.includes(selected.name) && (
                        <option value={o.value}>{t(o.key)}</option>
                      )
                  )}
                </select>
              </div>
            </div>
            {selected.component && (
              <React.Fragment>
                {createElement(selected.component, {
                  useContact: contact,
                  applications: candidaturesFiltered,
                  me: me
                })}
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(withTranslation(['jury', 'common'])(JuryList));
