/**
 * This component displays the folder interview grid for a candidate
 */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './InterviewGrade.scss';
import { ApplicationsResponse } from '../../../../../../services/api/Jury/Jury';
import { me } from '../../../../../../services/api/Contact/interface';
import Tooltip from '../../../../../../components/Tooltip/Tooltip';
import { Application_hed } from '../../../../../../services/api/Candidature/interface';
import Alert from '../../../../../../utils/Alert/Alert';
import _ from 'lodash';
import CandidatureService from '../../../../../../services//api/Candidature/CandidatureService';

interface ContainerProps {}

type ChildProps = {
  candidature: ApplicationsResponse;
  me: me;
  handleChange: (candidature: ApplicationsResponse) => void;
};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const InterviewGrade: React.FC<IProps> = (props) => {
  const { t, candidature, me, handleChange } = props;
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const [form, setForm] = useState<Application_hed>({});
  const [score, setScore] = useState<number>(0);
  const [checkboxSignature, setCheckboxSignature] = useState<boolean>(false);
  const [enableValidationButton, setEnableValidationButton] =
    useState<boolean>(true);
  const [scoreMotivation, setScoreMotivation] = useState<number>(0);
  const [scoreSoftSkills, setScoreSoftSkills] = useState<number>(0);
  const [readOnly, setReadonly] = useState<boolean>(false);
  let scoreItwTotal: number = scoreSoftSkills / 3 + scoreMotivation;

  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight, divRef.current?.clientWidth]);

  useEffect(() => {
    setForm(candidature);
    if (
      candidature.president_jury__c !== me.sfid ||
      (candidature.statut__c !== 'Convoqué Entretien' &&
        candidature.statut__c !== 'Entretien' &&
        candidature.statut__c !== 'Entretien planifié') ||
      candidature.signature_signature_jury_entretien__c
    ) {
      setReadonly(true);
    }
  }, [candidature]);

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (
      element &&
      element.clientHeight &&
      element.clientHeight > 0 &&
      element &&
      element.clientWidth &&
      element.clientWidth > 0
    ) {
      setHeight(element.clientHeight + 66 + 17 + 48 + 70);
      setWidth(element.clientWidth);
    }
  };

  const checkAllFieldNotEmpty = (
    necessaryField: string[],
    formToCheck: any
  ) => {
    let arrayFromFormKeys = Object.keys(formToCheck);
    return necessaryField.every(
      (item) => arrayFromFormKeys.indexOf(item) !== -1
    );
  };

  const arrayToTen: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const selectContents: any = {
    //parcours international
    communication__c: arrayToTen,
    connaissance_de_la_formation_de_junia_0_to_10__c: arrayToTen,
    connaissance_de_la_france__c: arrayToTen,
    connaissance_du_domaine_secteur_d_activi__c: arrayToTen,
    efficacit__c: arrayToTen,
    intellect__c: arrayToTen,
    ouverture_d_esprit__c: arrayToTen,
    projet_professionnel_clair_en_ad_quati__c: arrayToTen,
    relationnel__c: arrayToTen,
    si_exempt_d_cision_d_admission__c: ['admis', 'non admis'],
    //parcours français classique
    connaissance_de_la_formation_de_junia__c: [0, 1, 2, 3],
    argumentation_sur_le_projet_pro_tudes__c: [0, 1, 2, 3],
    ad_quation_du_parcours_pr_alable_par_rap__c: [0, 1, 2, 3],
    adequation_projet_formation__c: [0, 1, 2, 3],
    comprehension_du_metier_d_ingenieur__c: [0, 1, 2],
    projection_etudes_formation__c: [0, 1, 2],
    communication__c_fr: [0, 1, 2, 3, 4, 5, 6],
    ouverture_d_esprit__c_fr: [0, 1, 2, 3, 4, 5, 6],
    personnalite__c: [0, 1, 2, 3, 4, 5, 6],
    probabilite__c: ['Très forte', 'Forte', '50 /50', 'Faible'],
    international__c: [0, 1],
    reussite_formation__c: ['Oui', 'Non'],
    //parcours français apprenti
    presentation__c: [-1, 0, 1, 2],
    motivation__c: [-1, 0, 1, 2],
    personnalite__c_app: [-1, 0, 1, 2],
    recul__c: [-1, 0, 1, 2],
    ouverture_d_esprit__c_app: [-1, 0, 1, 2],
    international__c_app: [-1, 0, 1, 2],
    comprehension_du_metier_d_ingenieur__c_app: [-1, 0, 1, 2, 3, 4, 5],
    argumentation_du_projet_professionnel__c: [-1, 0, 1, 2, 3, 4, 5],
    entreprise__c: [-1, 0, 1, 2, 3, 4, 5],
    insertion__c: [-1, 0, 1, 2, 3, 4, 5],
    adequation_junia__c: [-1, 0, 1, 2, 3, 4],
    interet_pour_l_ecole__c: [-1, 0, 1, 2, 3, 4],
    entreprise_meme_que_postbac__c: ['Oui', 'Non']
  };

  const scoreFieldRequired: string[] =
    candidature?.Contact?.type_tech__c === 'International'
      ? [
          'communication__c',
          'connaissance_de_la_formation_de_junia__c',
          'connaissance_de_la_france__c',
          'connaissance_du_domaine_secteur_d_activi__c',
          'efficacit__c',
          'intellect__c',
          'ouverture_d_esprit__c',
          'projet_professionnel_clair_en_ad_quati__c',
          'relationnel__c'
        ]
      : candidature?.Contact?.type_tech__c === 'Echange'
      ? [
          'communication__c',
          'connaissance_de_la_formation_de_junia__c',
          'connaissance_de_la_france__c',
          'connaissance_du_domaine_secteur_d_activi__c',
          'efficacit__c',
          'intellect__c',
          'ouverture_d_esprit__c',
          'projet_professionnel_clair_en_ad_quati__c',
          'relationnel__c'
        ]
      : candidature?.Contact?.type_tech__c === 'Francais' &&
        (candidature?.Formation?.type_de_formation__c === 'Apprenti' ||
          candidature?.Formation?.type_de_formation__c ===
            'Formation France apprenti')
      ? [
          'presentation__c',
          'motivation__c',
          'personnalite__c',
          'recul__c',
          'ouverture_d_esprit__c',
          'international__c',
          'comprehension_du_metier_d_ingenieur__c',
          'entreprise__c',
          'insertion__c',
          'adequation_junia__c',
          'interet_pour_l_ecole__c',
          'projet_professionnel_clair_en_ad_quati__c'
        ]
      :  [
          'communication__c',
          'connaissance_de_la_formation_de_junia__c',
          'connaissance_de_la_france__c',
          'connaissance_du_domaine_secteur_d_activi__c',
          'efficacit__c',
          'intellect__c',
          'ouverture_d_esprit__c',
          'projet_professionnel_clair_en_ad_quati__c',
          'relationnel__c'
        ];

  const subTotalScoreFieldMotivation: string[] = [
    'connaissance_de_la_formation_de_junia__c',
    'argumentation_sur_le_projet_pro_tudes__c',
    'ad_quation_du_parcours_pr_alable_par_rap__c',
    'comprehension_du_metier_d_ingenieur__c',
    'projection_etudes_formation__c',
    'international__c',
    'adequation_projet_formation__c'
  ];

  const subTotalScoreFieldSoftSkills: string[] = [
    'communication__c',
    'ouverture_d_esprit__c',
    'personnalite__c'
  ];

  const requestField: string[] =
    candidature?.Contact?.type_tech__c === 'International'
      ? [
          'communication__c',
          'connaissance_de_la_formation_de_junia__c',
          'connaissance_de_la_france__c',
          'connaissance_du_domaine_secteur_d_activi__c',
          'efficacit__c',
          'intellect__c',
          'ouverture_d_esprit__c',
          'projet_professionnel_clair_en_ad_quati__c',
          'relationnel__c',
          'si_exempt_d_cision_d_admission__c',
          'signature_signature_jury_entretien__c'
        ]
      : candidature?.Contact?.type_tech__c === 'Francais' &&
        (candidature?.Formation?.type_de_formation__c === 'Apprenti' ||
          candidature?.Formation?.type_de_formation__c ===
            'Formation France apprenti')
      ? //apprenti
        [
          'adequation_junia__c',
          'argumentation_du_projet_professionnel__c',
          'comprehension_du_metier_d_ingenieur__c',
          'entreprise__c',
          'insertion__c',
          'interet_pour_l_ecole__c',
          'international__c',
          'motivation__c',
          'ouverture_d_esprit__c',
          'personnalite__c',
          'presentation__c',
          'probabilite__c',
          'promesse_et_contacts__c',
          'recul__c',
          'signature_signature_jury_entretien__c'
        ]
      : //classique
        [
          'adequation_projet_formation__c',
          'argumentation_sur_le_projet_pro_tudes__c',
          'commentaires_entretien__c',
          'communication__c',
          'comprehension_du_metier_d_ingenieur__c',
          'connaissance_de_la_formation_de_junia__c',
          'international__c',
          'ouverture_d_esprit__c',
          'personnalite__c',
          'probabilite__c',
          'projection_etudes_formation__c',
          'reussite_formation__c',
          'signature_signature_jury_entretien__c'
        ];

  const calculatingScore = (
    form: any,
    fieldRequired: string[],
    setScoreState: (value: React.SetStateAction<number>) => void
  ) => {
    let formFiltered = _.pick(form, fieldRequired);
    if (Object.keys(formFiltered).length > 0) {
      let scoreFromFiltered: any = Object.values(formFiltered).reduce(
        (a: any, b: any) =>
          (!a ? 0 : parseInt(a, 10)) + (!b ? 0 : parseInt(b, 10))
      );
      setScoreState(scoreFromFiltered);
    }
  };

  //rendre le bouton visible si tous les champs requis sont remplis
  useEffect(() => {
    checkAllFieldNotEmpty(requestField, form)
      ? setEnableValidationButton(false)
      : '';
    calculatingScore(form, scoreFieldRequired, setScore);
    calculatingScore(form, subTotalScoreFieldMotivation, setScoreMotivation);
    calculatingScore(form, subTotalScoreFieldSoftSkills, setScoreSoftSkills);
  }, [form, checkboxSignature]);

  const onChange = (e: any): void => {
    //envoie d'une alerte si les scores ne sont pas sélectionnés
    e.target.value === '' || null
      ? Alert.setAlert({
          duration: 5000,
          type: 'error',
          open: true,
          message: 'veuillez donner une note'
        })
      : '';

      let value;
      if (e.target.value === '') {
        value = null;
      } else if (!isNaN(e.target.value)) {
        value = Number(e.target.value);
      } else {
        value = e.target.value;
      }

    //gestion du nouveau formulaire pour envoyer les données au parent
    let newForm = {
      ...form,
      [e.target.name]: value
    };

    if (candidature?.Contact?.type_tech__c === 'International') {
      setForm(newForm);
    } else if (
      candidature?.Formation?.type_de_formation__c === 'Apprenti' ||
      candidature?.Formation?.type_de_formation__c ===
        'Formation France apprenti'
    ) {
      setForm({
        ...newForm,
        note_totale_entretien40_app_fr__c: score,
        note_totale_entretien20_app_fr_c__c: Number((score / 2).toFixed(2))
      });
    } else {
      setForm({
        ...newForm,
        total_savoir_etre__c: scoreSoftSkills,
        note_finale_entretien_sur_20_etufr__c: Number(scoreItwTotal.toFixed(2))
      });
    }
  };
  const sendItwGrade = async (userId: string) => {
    let sendGrade = await CandidatureService.patchCandidature(userId, {
      ...form,
      id_heroku__c: candidature?.id_heroku__c
    });
    sendGrade.status === 203
      ? Alert.setAlert({
          duration: 3000,
          type: 'success',
          open: true,
          message: 'Merci, l’évaluation est bien enregistrée.'
        })
      : Alert.setAlert({
          duration: 3000,
          type: 'error',
          open: true,
          message: 'Une erreur est survenue'
        });
  };

  return (
    <div className="folder-grade">
      <div ref={divRef}>
        <div className="folder-header">
          <h5>{t('evalFolder')}</h5>
          <div className="validate">
            <div>
              <h6>{t('candidate')}</h6>
              <p>
                {candidature?.Contact?.firstname}{' '}
                {candidature?.Contact?.lastname}
              </p>
            </div>
            <div>
              {!candidature.signature_signature_jury_entretien__c &&
                candidature.president_jury__c && (
                  <button
                    className="btn btn-main"
                    disabled={enableValidationButton}
                    onClick={() => {
                      sendItwGrade(me?.id_heroku__c || '');
                    }}>
                    <span>{t('validate')}</span>
                  </button>
                )}
            </div>
          </div>
          <hr></hr>
        </div>
      </div>
      <div
        className="folder-grade-scroll">
        <div className="folder-grade-scroll-content">
          {/* 
          // -- DEBUT PARCOURS INTERNATIONAL --
          */}
          {(candidature?.Contact?.type_tech__c === 'International' || candidature?.Contact?.type_tech__c === 'Echange') && (
            <div>
              <div className="body">
                <h4>{t('knowledge')}</h4>
                <h6>{t('knowledgeJunia')}</h6>
                <select
                  name="connaissance_de_la_formation_de_junia__c"
                  value={form.connaissance_de_la_formation_de_junia__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.connaissance_de_la_formation_de_junia_0_to_10__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>{t('knowledgeDomain')}</h6>
                <select
                  name="connaissance_du_domaine_secteur_d_activi__c"
                  value={form.connaissance_du_domaine_secteur_d_activi__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.connaissance_du_domaine_secteur_d_activi__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>{t('workProjet')}</h6>
                <select
                  name="projet_professionnel_clair_en_ad_quati__c"
                  value={form.projet_professionnel_clair_en_ad_quati__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.projet_professionnel_clair_en_ad_quati__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>{t('knowledgeFr')}</h6>
                <select
                  name="connaissance_de_la_france__c"
                  value={form.connaissance_de_la_france__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.connaissance_de_la_france__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h4>{t('dimensions')}</h4>
                <h6>{t('communication')}</h6>
                <select
                  name="communication__c"
                  value={form.communication__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.communication__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>{t('intellect')}</h6>
                <select
                  name="intellect__c"
                  value={form.intellect__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.intellect__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>{t('relational')}</h6>
                <select
                  name="relationnel__c"
                  value={form.relationnel__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.relationnel__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>{t('efficiency')}</h6>
                <select
                  name="efficacit__c"
                  value={form.efficacit__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.efficacit__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>{t('openMind')}</h6>
                <select
                  name="ouverture_d_esprit__c"
                  value={form.ouverture_d_esprit__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.ouverture_d_esprit__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
              <div className="footer">
                <h5>{t('totalGrade')}</h5>
                <h6>{t('totalGradeItw')}</h6>
                <input
                  value={`${((score * 2) / 9).toFixed(1)} / 20 points`}
                  name="note_finale_entretien__c"
                  readOnly
                />
                <h5>{t('decision')}</h5>
                <h6>{t('entryDecision')}</h6>
                <select
                  name="d_cision_entretien__c"
                  value={form.d_cision_entretien__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value=""></option>
                  <option value="Admis">Admis</option>
                  <option value="Non admis">Non admis</option>
                  <option value="En attente">En attente</option>
                </select>
                <h6>
                  <Tooltip message={t('tooltips.commentItwInt')}></Tooltip>
                  {t('commentItw')}
                </h6>
                <textarea
                  name="commentaires_entretien__c"
                  value={form.commentaires_entretien__c}
                  onChange={onChange}
                  rows={10}
                  disabled={readOnly}></textarea>
                <h6>{t('jurySignatureItw')}</h6>
                <input
                  type="checkbox"
                  defaultChecked={form.signature_signature_jury_entretien__c}
                  value="true"
                  onChange={(e) => {
                    setCheckboxSignature(!checkboxSignature);
                    setForm((form) => ({
                      ...form,
                      signature_signature_jury_entretien__c: !checkboxSignature
                    }));
                  }}
                  disabled={readOnly}
                />
              </div>
            </div>
          )}
          {/* 
          // -- FIN PARCOURS INTERNATIONAL --
          */}
          {/* 
          // -- DEBUT PARCOURS FRANCAIS CLASSIQUE --
          */}
          {candidature?.Contact?.type_tech__c === 'Francais' &&
            candidature?.Formation?.type_de_formation__c !== 'Apprenti' &&
            candidature?.Formation?.type_de_formation__c !==
              'Formation France apprenti' && (
              <div className="body">
                <h5>{`${t('motivation')} / 14`}</h5>
                <h6>
                  <Tooltip message={t('tooltips.knowledgeJunia')}></Tooltip>
                  {t('knowledgeJunia')}
                </h6>
                <select
                  name="connaissance_de_la_formation_de_junia__c"
                  value={form.connaissance_de_la_formation_de_junia__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.connaissance_de_la_formation_de_junia__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>
                  <Tooltip message={t('tooltips.workRelevance')}></Tooltip>
                  {t('workRelevance')}
                </h6>
                <select
                  name="argumentation_sur_le_projet_pro_tudes__c"
                  value={form.argumentation_sur_le_projet_pro_tudes__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.argumentation_sur_le_projet_pro_tudes__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>
                  <Tooltip message={t('tooltips.workRelevance')}></Tooltip>
                  {t('adequationProjet')}
                </h6>
                <select
                  name="adequation_projet_formation__c"
                  value={form.adequation_projet_formation__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.ad_quation_du_parcours_pr_alable_par_rap__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>
                  <Tooltip message={t('tooltips.engineer')}></Tooltip>
                  {t('engineer')}
                </h6>
                <select
                  name="comprehension_du_metier_d_ingenieur__c"
                  value={form.comprehension_du_metier_d_ingenieur__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.comprehension_du_metier_d_ingenieur__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>
                  <Tooltip message={t('tooltips.engineer')}></Tooltip>
                  {t('projection')}
                </h6>
                <select
                  name="projection_etudes_formation__c"
                  value={form.projection_etudes_formation__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.projection_etudes_formation__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>
                  <Tooltip message={t('tooltips.worldInterest')}></Tooltip>
                  {t('worldInterest')}
                </h6>
                <select
                  name="international__c"
                  value={form.international__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.international__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>{t('motivationScore')}</h6>
                <input
                  name="total_motivation__c"
                  value={`${scoreMotivation} / 14 points`}
                  readOnly
                />
                <h4>{`${t('softSkillsItw')} / 6`}</h4>
                <h6>
                  <Tooltip message={t('tooltips.communicationItw')}></Tooltip>
                  {t('communicationItw')}
                </h6>
                <select
                  name="communication__c"
                  value={form.communication__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.communication__c_fr.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>
                  <Tooltip message={t('tooltips.openMindItw')}></Tooltip>
                  {t('openMindItw')}
                </h6>
                <select
                  name="ouverture_d_esprit__c"
                  value={form.ouverture_d_esprit__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.ouverture_d_esprit__c_fr.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>
                  <Tooltip message={t('tooltips.personality')}></Tooltip>
                  {t('personality')}
                </h6>
                <select
                  name="personnalite__c"
                  value={form.personnalite__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.personnalite__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>{t('softSkillsScore')}</h6>
                <input
                  name="total_savoir_etre__c"
                  value={`${(scoreSoftSkills / 3).toFixed(1)} / 6 points`}
                  readOnly
                />
              </div>
            )}
          {/* 
          // -- FIN PARCOURS FRANCAIS CLASSIQUE --
          */}
          {/* 
          // -- DEBUT PARCOURS FRANCAIS APPRENTI --
          */}
          {candidature?.Contact?.type_tech__c === 'Francais' && (
            <>
              {(candidature?.Formation?.type_de_formation__c === 'Apprenti' ||
                candidature?.Formation?.type_de_formation__c ===
                  'Formation France apprenti') && (
                <div className="body">
                  <h4>{t('softSkillsItw')}</h4>
                  <h6>
                    <Tooltip message={t('tooltips.presentation')}></Tooltip>
                    {t('presentation')}
                  </h6>
                  <select
                    name="presentation__c"
                    value={form.presentation__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.presentation__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.motivationItw')}></Tooltip>
                    {t('motivation')}
                  </h6>
                  <select
                    name="motivation__c"
                    value={form.motivation__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.motivation__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.personalityItw')}></Tooltip>
                    {t('personality')}
                  </h6>
                  <select
                    name="personnalite__c"
                    value={form.personnalite__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.personnalite__c_app.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.personalityItw')}></Tooltip>
                    {t('stepBack')}
                  </h6>
                  <select
                    name="recul__c"
                    value={form.recul__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.recul__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.openMindItwApp')}></Tooltip>
                    {t('openMindItw')}
                  </h6>
                  <select
                    name="ouverture_d_esprit__c"
                    value={form.ouverture_d_esprit__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.ouverture_d_esprit__c_app.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.international')}></Tooltip>
                    {t('worldInterest')}
                  </h6>
                  <select
                    name="international__c"
                    value={form.international__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.international__c_app.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h4> {t('engineerProjection')}</h4>
                  <h6>
                    <Tooltip message={t('tooltips.engineerApp')}></Tooltip>
                    {t('engineer')}
                  </h6>
                  <select
                    name="comprehension_du_metier_d_ingenieur__c"
                    value={form.comprehension_du_metier_d_ingenieur__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.comprehension_du_metier_d_ingenieur__c_app.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.proProjectApp')}></Tooltip>
                    {t('proProject')}
                  </h6>
                  <select
                    name="projet_professionnel_clair_en_ad_quati__c"
                    value={form.projet_professionnel_clair_en_ad_quati__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.argumentation_du_projet_professionnel__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h4> {t('learningProjection')}</h4>
                  <h6>
                    <Tooltip message={t('tooltips.company')}></Tooltip>
                    {t('company')}
                  </h6>
                  <select
                    name="entreprise__c"
                    value={form.entreprise__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.entreprise__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.insertion')}></Tooltip>
                    {t('insertion')}
                  </h6>
                  <select
                    name="insertion__c"
                    value={form.insertion__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.insertion__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h4>{t('juniaProjection')}</h4>
                  <h6>
                    <Tooltip message={t('tooltips.adequationApp')}></Tooltip>
                    {t('adequationApp')}
                  </h6>
                  <select
                    name="adequation_junia__c"
                    value={form.adequation_junia__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.adequation_junia__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.schoolInterest')}></Tooltip>
                    {t('schoolInterest')}
                  </h6>
                  <select
                    name="interet_pour_l_ecole__c"
                    value={form.interet_pour_l_ecole__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.interet_pour_l_ecole__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              )}
            </>
          )}
          {/* 
          // -- FIN PARCOURS FRANCAIS APPRENTI --
          */}
          {/* 
          // -- DEBUT PARCOURS FRANCAIS COMMUN 
          */}
          {candidature?.Contact?.type_tech__c === 'Francais' && (
              <div className="footer">
                <h5>{t('totalGrade')}</h5>
                {candidature?.Formation?.type_de_formation__c === 'Apprenti' ||
                candidature?.Formation?.type_de_formation__c ===
                  'Formation France apprenti' ? (
                  //----- SI APPRENTI -----
                  <>
                    <h6>{t('totalGrade')}</h6>
                    <input
                      value={`${score} / 40 points`}
                      name="note_totale_entretien40_app_fr__c"
                      readOnly
                    />
                    <h6>{t('totalGradeItw')}</h6>
                    <input
                      value={`${(score / 2).toFixed(2)} / 20 points`}
                      name="note_finale_entretien__c"
                      readOnly
                    />
                    <h5>{t('situation')}</h5>
                    <h6>{t('promise')}</h6>

                    <textarea
                      onChange={onChange}
                      onBlur={onChange}
                      name="promesse_et_contacts__c"
                      value={form.promesse_et_contacts__c}
                      disabled={readOnly}
                    />
                    <h6>{t('prospectiveCompany')}</h6>
                    <select
                      name="entreprise_meme_que_postbac__c"
                      value={form.entreprise_meme_que_postbac__c}
                      onChange={onChange}
                      onBlur={onChange}
                      disabled={readOnly}>
                      <option value={''}></option>
                      {selectContents.entreprise_meme_que_postbac__c.map(
                        (item: number, index: number) => {
                          return (
                            <option key={index} value={item}>
                              {item.toString()}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </>
                ) : (
                  //----- SI CLASSIQUE -----
                  <>
                    <h6>{t('totalGradeItw')}</h6>
                    <input
                      value={`${scoreItwTotal.toFixed(2)} / 20 points`}
                      name="note_finale_entretien_sur_20_etufr__c"
                      readOnly
                    />
                  </>
                )}
                <h5>
                  <Tooltip message={t('tooltips.probability')}></Tooltip>
                  {t('probability')}
                </h5>
                <select
                  name="probabilite__c"
                  value={form.probabilite__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.probabilite__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                <h6>
                  <Tooltip message={t('tooltips.otherCandidates')}></Tooltip>
                  {t('otherCandidates')}
                </h6>
                <textarea
                  onChange={onChange}
                  name="autres_candidatures__c"
                  value={form.autres_candidatures__c}
                  disabled={readOnly}
                />
                <h6>{t('commentItw')}</h6>
                <textarea
                  name="commentaires_entretien__c"
                  value={form.commentaires_entretien__c}
                  onChange={onChange}
                  rows={10}
                  disabled={readOnly}></textarea>

                {/* -- CHAMPS REUSSITE  QUE POUR LES CLASSIQUES --*/}
                {candidature?.Formation?.type_de_formation__c !== 'Apprenti' &&
                  candidature?.Formation?.type_de_formation__c !==
                    'Formation France apprenti' && (
                    <>
                      <h6>{t('success')}</h6>
                      <select
                        name="reussite_formation__c"
                        value={form.reussite_formation__c}
                        onChange={onChange}
                        onBlur={onChange}
                        disabled={readOnly}>
                        <option value={''}></option>
                        {selectContents.reussite_formation__c.map(
                          (item: number, index: number) => {
                            return (
                              <option key={index} value={item}>
                                {item.toString()}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </>
                  )}
                {/* -- CHAMPS REUSSITE  QUE POUR LES CLASSIQUES --*/}

                <h6>{t('jurySignatureItw')}</h6>
                <input
                  type="checkbox"
                  defaultChecked={form.signature_signature_jury_entretien__c}
                  value="true"
                  onChange={(e) => {
                    setCheckboxSignature(!checkboxSignature);
                    setForm((form) => ({
                      ...form,
                      signature_signature_jury_entretien__c: !checkboxSignature
                    }));
                  }}
                  disabled={readOnly}
                />
              </div>
          )}
          {/* 
          // -- FIN PARCOURS COMMUN FRANCAIS  --
          */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['jury', 'common'])(InterviewGrade));
