/**
 * This component displays the requested files to upload for an application
 */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import LeftArrow from '../../../../assets/images/junia-icon-fleche-3.png';

import './Candidature.scss';
import UploadFile from '../../../../components/UploadFile/UploadFile';
import _ from 'lodash';
import { Application_hed } from '../../../../services/api/Candidature/interface';
import {
  FileToUpload
} from '../../../../services/api/Files/interface';
import FilesService from '../../../../services/api/Files/FilesService';
import ContactService from '../../../../services/api/Contact/ContactService';
import { me } from '../../../../services/api/Contact/interface';
import { Contact } from '../../../../services/api/Contact/interface';
import DisplayFiles from '../../../../components/DisplayFiles/DisplayFiles';
import DisplayBlobFiles from '../../../../components/DisplayBlobFiles/DisplayBlobFiles';

interface ContainerProps {
  previous: () => void;
  next: () => void;
  save: () => void;
  me?: me;
  applicationData: Application_hed;
  getApplication?: () => void;
  onStep: (
    application: Application_hed,
    step: number,
    files?: FileToUpload[]
  ) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
  ChildProps,
  WithTranslation,
  RouteComponentProps { }

const Candidature: React.FC<IProps> = (props) => {
  const {
    t,
    onStep,
    applicationData,
    getApplication,
    me
  } = props;
  const [height, setHeight] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const [fileList, setFileList] = useState<FileToUpload[]>([]);
  const [canNext, setCanNext] = useState<boolean>(false);
  const { candidate_id } = useParams<{ candidate_id: string }>();
  const [contact, setContact] = useState<Contact>({});
  useEffect(() => {
    getContact();
  }, [me]);
  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight]);
  useEffect(() => {
    let list = [''];
    let candidateTypeTech = candidate_id ? contact.type_tech__c : me?.type_tech__c;
    if (candidateTypeTech === 'Francais') {
      list = ['poursuite', 'motivation'];
    } else if (candidateTypeTech === 'International') {
      list = ['motivation'];
    } else if (candidateTypeTech === 'Echange') {
      list = ['motivation', 'plan'];
    }
    // let list = ['poursuite', 'motivation'];
    // if (
    //   applicationData.Formation?.type_de_formation__c ===
    //   'Formation France apprenti'
    // ) {
    //   list.push('recommendation');
    // }
    let next = checkHaveFiles(list);
    setCanNext(next);
  });
  useEffect(() => {
    let list = [''];
    let candidateTypeTech = candidate_id ? contact.type_tech__c : me?.type_tech__c;
    if (candidateTypeTech === 'Francais') {
      list = ['poursuite', 'motivation'];
    } else if (candidateTypeTech === 'International') {
      list = ['motivation'];
    } else if (candidateTypeTech === 'Echange') {
      list = ['motivation', 'plan'];
    }
    // let list = ['poursuite', 'motivation'];
    // if (
    //   applicationData.Formation?.type_de_formation__c ===
    //   'Formation France apprenti'
    // ) {
    //   list.push('recommendation');
    // }
    let next = checkHaveFiles(list);
    setCanNext(next);
  }, [applicationData]);
  useEffect(() => {
    let list = [''];
    let candidateTypeTech = candidate_id ? contact.type_tech__c : me?.type_tech__c;
    if (candidateTypeTech === 'Francais') {
      list = ['poursuite', 'motivation'];
    } else if (candidateTypeTech === 'International') {
      list = ['motivation'];
    } else if (candidateTypeTech === 'Echange') {
      list = ['plan'];
    }
    // let list = ['poursuite', 'motivation'];
    // if (
    //   applicationData.Formation?.type_de_formation__c ===
    //   'Formation France apprenti'
    // ) {
    //   list.push('recommendation');
    // }
    let next = checkHaveFiles(list);
    setCanNext(next);
  }, [fileList]);

  const getContact = async () => {
    try {
      const contactId = candidate_id ? candidate_id : me?.id_heroku__c;
      if (contactId) {
        const contactResponse = await ContactService.getContact(contactId);
        setContact(contactResponse.data);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ getContact ~ error',
        error
      );
    }
  };

  const checkHaveFiles = (types: string[]) => {
    let returnValue = true;
    _.map(types, (type) => {
      let foundSaved = _.find(
        applicationData.Files,
        (file) => file.type__c === type
      );
      let foundBlob = _.find(fileList, (file) => file.type === type);
      if ((!foundBlob || foundBlob.files.length < 1) && !foundSaved) {
        returnValue = false;
      }
    });
    return returnValue;
  };

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (element && element.clientHeight && element.clientHeight > 0) {
      setHeight(element.clientHeight + 8 + 70 + 48); //height + padding div + menu size
    }
  };

  const handleFileChange = (files: File[], type: string) => {
    let list = [...fileList];
    let found = _.findIndex(list, (f) => f.type === type);
    if (found >= 0) {
      let data = [...list[found].files, ...files];
      list.splice(found, 1, { type: type, files: data });
    } else {
      list.push({ type: type, files: files });
    }
    setFileList(list);
    setCanNext(true);
    // onChange({ data: form, files: { type: type, files: files } }, true);
  };
  const handleClick = (step: number) => {
    onStep(applicationData, step, fileList);
  };

  const handleFileDelete = (file: any, type: string, fileType: string) => {
    let contactId = candidate_id ? candidate_id : me?.id_heroku__c;
    switch (fileType) {
      case 'uploaded':
        if (file?.id_heroku__c && contactId) {
          FilesService.removeFile(file?.id_heroku__c, contactId).then(
            (res) => {
              let found = _.find(fileList, (f) => f.type === type);

              if (getApplication) {
                getApplication();

                // setFileList([])
              }
            }
          );
        }
        break;
      case 'blob':
        var liste = [...fileList];
        var index = _.findIndex(liste, (f) => f.type === file.type);
        var fileIndex = _.findIndex(
          liste[index].files,
          (f: File) =>
            f.lastModified === file.files[0].lastModified &&
            f.name === file.files[0].name
        );
        var array: File[] = liste[index].files;
        array.splice(fileIndex, 1);
        liste[index].files = array;
        setFileList(liste);

        break;
    }
  };

  const findMax = (type: string, max: number) => {
    let foundCurrent = _.find(fileList, (f) => f.type === type);
    let foundSave = _.filter(applicationData.Files, (f) => f.type__c === type);
    let currentLength = foundCurrent ? foundCurrent.files.length : 0;
    let saveLength = foundSave ? foundSave.length : 0;
    return currentLength + saveLength;
  };

  return (
    <div className="apply-container general-container">
      <div className="apply-elements">
        <div ref={divRef} className="apply-header">
          <button className="btn btn-return" onClick={() => handleClick(-1)}>
            <img alt="left arrow" src={LeftArrow} />
            <span>{t('return')}</span>
          </button>
          <div className="app-header-title">
            <div className="left-element">
              <h3>{t('applyTitle')}</h3>
            </div>
            <div>
              {canNext ? (
                <button
                  className="btn btn-main"
                  onClick={() => handleClick(+1)}>
                  <span>{t('next')}</span>
                </button>
              ) : (
                <button className="btn btn-disabled">
                  <span>{t('next')}</span>
                </button>
              )}
            </div>
          </div>
          <h5>{t('applyDoc')}</h5>
          <span>
            <span style={{ color: 'red', fontSize: '1rem' }}>*</span>{' '}
            {t('word.required')}
          </span>
        </div>
        <div
          className="apply-list-container"
          style={{ height: 'calc(98% - ' + height + 'px)' }}>
          <div className="apply-list">
            <div className="docs-justif-top">
              <div className="docs-justif-right">
                <div>
                  <h6>
                    {t('motivLetter')} 
                    {contact.type_tech__c!=='Echange' && (<span className="form-required">*</span>)}
                  </h6>
                </div>
                <UploadFile
                  key="motivation-upload"
                  onChange={(data) => handleFileChange(data, 'motivation')}
                  max={1}
                  current={findMax('motivation', 1)}
                  type="pdf"></UploadFile>
                <DisplayBlobFiles
                  files={fileList}
                  type="motivation"
                  onDelete={(data) =>
                    handleFileDelete(data, 'motivation', 'blob')
                  }></DisplayBlobFiles>
                <DisplayFiles
                  files={applicationData.Files}
                  type="motivation"
                  onDelete={(data) =>
                    handleFileDelete(data, 'motivation', 'uploaded')
                  }></DisplayFiles>
              </div>
              <div className="docs-justif-left">
                <p>{t('motivText')}</p>
              </div>
            </div>
            <div className="docs-justif-top">
              <div className="docs-justif-right">
                {contact?.type_tech__c === 'Francais' && (
                  <>
                    <div>
                      <h6>
                        {t('poursuite')}
                        <span className="form-required">*</span>
                      </h6>
                    </div>
                    <UploadFile
                      key="poursuite-upload"
                      onChange={(data) => handleFileChange(data, 'poursuite')}
                      max={1}
                      current={findMax('poursuite', 1)}
                      type="pdf"></UploadFile>
                    <DisplayBlobFiles
                      files={fileList}
                      type="poursuite"
                      onDelete={(data) =>
                        handleFileDelete(data, 'poursuite', 'blob')
                      }></DisplayBlobFiles>
                    <DisplayFiles
                      files={applicationData.Files}
                      type="poursuite"
                      onDelete={(data) =>
                        handleFileDelete(data, 'poursuite', 'uploaded')
                      }></DisplayFiles>
                  </>
                )}
              </div>
              <div className="docs-justif-left">
                {contact?.type_tech__c === 'Francais' && <p>{t('poursuiteText')}</p>}
                {contact?.type_tech__c === 'Francais' && <p>{t('refuseText')}</p>}
              </div>
            </div>
            <div className="docs-justif-top">
              <div className="docs-justif-right">
                {contact?.type_tech__c === 'Echange' && (
                  <>
                    <h6>
                      {t('plan')} <span className="form-required">*</span>
                    </h6>
                    <UploadFile
                      key="plan-upload"
                      onChange={(data) => handleFileChange(data, 'plan')}
                      max={1}
                      current={findMax('plan', 1)}
                      type="pdf"></UploadFile>
                    <DisplayBlobFiles
                      files={fileList}
                      type="plan"
                      onDelete={(data) =>
                        handleFileDelete(data, 'plan', 'blob')
                      }></DisplayBlobFiles>
                    <DisplayFiles
                      files={applicationData.Files}
                      type="plan"
                      onDelete={(data) =>
                        handleFileDelete(data, 'plan', 'uploaded')
                      }></DisplayFiles>
                  </>
                )}
                <h6>{t('recoLetter')}</h6>
                <UploadFile
                  key="recommendation-upload"
                  onChange={(data) => handleFileChange(data, 'recommendation')}
                  max={1}
                  current={findMax('recommendation', 1)}
                  type="pdf"></UploadFile>
                <DisplayBlobFiles
                  files={fileList}
                  type="recommendation"
                  onDelete={(data) =>
                    handleFileDelete(data, 'recommendation', 'blob')
                  }></DisplayBlobFiles>
                <DisplayFiles
                  files={applicationData.Files}
                  type="recommendation"
                  onDelete={(data) =>
                    handleFileDelete(data, 'recommendation', 'uploaded')
                  }></DisplayFiles>
              </div>
              {contact?.type_tech__c === 'Francais' && <div></div>}
            </div>
            <div className="other-docs">
              <div className="other-left">
                <h6>{t('otherDocs')}</h6>
                <p>{t('otherDocsInfo')}</p>
                <div className="upload-orange-container">
                  <UploadFile
                    key="documents-upload"
                    onChange={(data) => handleFileChange(data, 'documents')}
                    max={contact?.type_tech__c === "International" ? 3 : 500}
                    current={findMax('documents', contact?.type_tech__c === "International" ? 3 : 500)}
                    type="pdf"></UploadFile>
                  {/* <label className="upload-orange" htmlFor="uploadOrange">
                        <p>
                          <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                          {t('addDoc')}
                        </p>
                      </label> */}
                </div>
                <div className="already-upload">
                  <h6>{t('alreadyUpload')}</h6>
                  <DisplayBlobFiles
                    files={fileList}
                    type="documents"
                    onDelete={(data) =>
                      handleFileDelete(data, 'documents', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={applicationData.Files}
                    type="documents"
                    onDelete={(data) =>
                      handleFileDelete(data, 'documents', 'uploaded')
                    }></DisplayFiles>
                </div>
              </div>
              {contact?.type_tech__c === 'Francais' && (
                <div className="other-right">
                  <p>{t('otherDocsSentence.headerList')}</p>
                  <p>{t('otherDocsSentence.list1')}</p>
                  <p>{t('otherDocsSentence.list2')}</p>
                  <p>{t('otherDocsSentence.list3')}</p>
                  <p>{t('otherDocsSentence.list4')}</p>
                  <p>{t('otherDocsSentence.list5')}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['home', 'common', 'application'])(Candidature)
);
