/**
 * This component display the list of applications related to the connected Jury
 */
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import './Folder.scss';
import moment from 'moment';
import _ from 'lodash';
import { ApplicationsResponse } from '../../../../services/api/Jury/Jury';
import { Check, Close } from '@mui/icons-material';
import { me } from '../../../../services/api/Contact/interface';
import LanguageIcon from '@mui/icons-material/Language';
import flagFR from '../../../../assets/images/flagFR.png';
import echangeLogo from '../../../../assets/images/junia-icon-echange.png';
import applicationPicklist from '../../../../assets/data/hed_application.json';

interface ContainerProps {
  applications: ApplicationsResponse[];
  me: me;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
  ChildProps,
  WithTranslation,
  RouteComponentProps { }

const Folder: React.FC<IProps> = (props) => {
  const { t, applications, me } = props;
  const history = useHistory();
  const openCandidature = async (id: any) => {
    history.push(`/jury/folder/${id}`);
  };

  return (
    <div>
      <div className="legend">
        <div className="status">
          <span className="proceed"></span>
          <p>{t('toTreat')}</p>
        </div>
        <div className="status">
          <span className="finish"></span>
          <p>{t('finish')}</p>
        </div>
      </div>
      <table className="folder-table">
        <thead>
          <tr>
            <td width={'5%'}>{t('status')}</td>
            <td width={'5%'}>{t('type')}</td>
            <td width={'10%'}>{t('folder')}</td>
            <td width={'10%'}>{t('lastname')}</td>
            <td width={'10%'}>{t('firstname')}</td>
            <td width={'20%'}>{t('formation')}</td>
            <td width={'10%'}>{t('date')}</td>
            <td width={'10%'}>{t('step')}</td>
            <td width={'10%'}>{t('convocationInt')}</td>
            <td width={'10%'}>{t('jurySignature')}</td>
          </tr>
        </thead>
        <tbody>
          {applications?.length > 0 ? (
            _.chain(applications)
              .map((application) => {
                if (
                  (application?.statut__c === 'Convoqué Entretien' ||
                    application?.statut__c === 'Entretien' ||
                    application?.statut__c === 'Entretien planifié' ||
                    application?.statut__c === 'En attente' ||
                    application?.statut__c === 'Admis' ||
                    application?.statut__c === 'Admis sous réserve' ||
                    application?.statut__c === 'Non admis' ||
                    application?.statut__c === 'Abandon' ||
                    application?.statut__c ===
                    'Admis ayant accepté la proposition' ||
                    application?.statut__c === "Liste d'attente" ||
                    application?.statut__c === 'Sans suite' ||
                    application?.statut__c === 'Refus' ||
                    application?.statut__c === 'Attente jury fin d’année' ||
                    application?.statut__c === 'Attente jury fin d’année (partenaire)' ||
                    application?.statut__c === 'Etude du dossier') &&
                  application.jury_dossier__c === me.sfid
                ) {
                  //translate dur_e_de_l_change__c and specialite_principale_Junia__c values in french since the API name is in english
                  let dureeEchangeInFrench;
                  switch (application.dur_e_de_l_change__c) {
                    case "To fill in":
                      dureeEchangeInFrench = "A renseigner";
                      break;
                    case "One semester":
                      dureeEchangeInFrench = "Un semestre";
                      break;
                    case "One academic year":
                      dureeEchangeInFrench = "Une année académique";
                      break;
                    case "Joint Degree":
                      dureeEchangeInFrench = "Un double diplôme	";
                  };

                  //Translate specialite_principale_junia__c
                  let specialiteJuniaFr = "";
                  {
                    applicationPicklist.picklistFieldValues[
                      'Specialite_principale_Junia__c'
                    ].values.map((spe) => {
                      console.log("SPE JUNIA ENGLISH: --> ", spe.value);
                      if (application.specialite_principale_junia__c && spe.value == application.specialite_principale_junia__c) {
                        specialiteJuniaFr = spe.label;
                      }
                      else {
                        console.error("No french value for: ", application.specialite_principale_junia__c);
                      }
                    });
                  }

                  const concatFormationEchange = application.programme__c + '-' + specialiteJuniaFr + '-' + dureeEchangeInFrench?.toString();
                  return (
                    <>
                      <tr
                        onClick={() =>
                          openCandidature(application?.id_heroku__c)
                        }
                        key={application.id_heroku__c}>
                        <td className="status">
                          <div
                            className={
                              application.statut__c === 'Etude du dossier' &&
                                ((application.Contact?.type_tech__c === 'International' || application.Contact?.type_tech__c === 'Echange' )?
                                  !application.signature_jury_dossier__c
                                  : !application.signature_du_correcteur__c) ?
                                'proceed'
                                : 'finish'
                            }>
                            <span></span>
                          </div>
                        </td>
                        <td>
                          <p>
                            {application.Contact?.type_tech__c ===
                              'Francais' ? (
                              <img
                                style={{ width: '50%' }}
                                src={flagFR}
                                alt="drapeau français"
                              />
                            ) : application.Contact?.type_tech__c ===
                              'Echange' ? (
                              <img
                                style={{ width: '50%' }}
                                src={echangeLogo}
                                alt="logo echange"
                              />
                            ) :
                              (
                                <LanguageIcon color='primary' />
                              )
                            }
                          </p>
                        </td>
                        <td>
                          <p>{application.name}</p>
                        </td>
                        <td>
                          <p>{application.Contact?.lastname}</p>
                        </td>
                        <td>
                          <p>{application.Contact?.firstname}</p>
                        </td>
                        <td>
                          <p>
                            {
                              application.Contact?.type_tech__c === 'Echange' ?
                                concatFormationEchange :
                                application?.Formation?.nom_formation_tech__c
                            }
                          </p>
                        </td>
                        <td>
                          <p>
                            {moment(application.date_jury_dossier__c).format(
                              'DD/MM/YYYY'
                            )}
                          </p>
                        </td>
                        <td>
                          <p>{application.statut__c}</p>
                        </td>
                        <td>
                          <p>
                            {application.convocation_entretien__c ?
                              application.convocation_entretien__c
                              : application.convoqu_entretien_fr__c ?
                                application.convoqu_entretien_fr__c
                                : null
                            }
                          </p>
                        </td>
                        <td>
                          <p>
                            {(application.Contact?.type_tech__c ===
                              'International' || application.Contact?.type_tech__c ===
                              'Echange' )? (
                              application.signature_jury_dossier__c ? (
                                <Check color="success" />
                              ) : (
                                <Close color="warning" />
                              )
                            ) : application.signature_du_correcteur__c ? (
                              <Check color="success" />
                            ) : (
                              <Close color="warning" />
                            )}
                          </p>
                        </td>
                      </tr>
                    </>
                  );
                }
              })
              .value()
          ) : (
            <tr>
              <td>
                <i>Aucun dossier à traiter</i>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default withRouter(
  withTranslation(['jury', 'contact', 'common'])(Folder)
);
